import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  input_username: string;
  input_password: string;
  form = new FormGroup({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(4)
    ])
  });
  dialogRef: MatDialogRef<PopupFeedbackComponent>;
  constructor(private dialog: MatDialog, private router: Router, private cactusService: CactusService,
     private constantsService: ConstantsService) {}
  ngOnInit() {
    // this.router.navigate(['/main']);

  }

  get username() {
    return this.form.get('username');
  }
  get password() {
    return this.form.get('password');
  }
  openDialog(message: string) {
    const dialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: 'Erreur d\'authentification', showAction: false, color: '#FFffff', bg_color: '#F44336' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  login() {
    const myData  = {
      email: this.input_username,
      password: this.input_password,
    };
    this.cactusService.pushDataSignInAdmin(this.constantsService.urlLoginAdmin, myData).subscribe(res => {
      if (res.status === 0) {
        this.openDialog(res.message);
        this.form.setErrors({
          invalid: true
        });
      } else {
        // set Admin data in session storage
        localStorage.setItem('token', res.data.token);
        this.router.navigate(['/main']);
      }
    });
  }

}
