import { CactusService } from './../../api/cactus.service';
import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConstantsService } from '../../utils/constants.service';

@Injectable()
export class ArchiveUserProfileResolver implements Resolve<any> {
    constructor(private cactus: CactusService, private constants: ConstantsService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return this.cactus.pushData(this.constants.urlArchivedUserProfile, {'id': route.paramMap.get('id')}).pipe(catchError(error => {
            return of('data not available at this time');
          }));
      }
}
