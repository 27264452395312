import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';

import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';

@Injectable()
export class AbusesResolver implements Resolve<any> {
    constructor(private cactus: CactusService, private constants: ConstantsService) {}

    resolve() {
        return this.cactus.getData(this.constants.urlLoadAbusesList).pipe(catchError(error => {
          return of('data not available at this time' + error);
        }));
        // return this.cactus.getData();
      }

      // .pipe(map(this.extractData), tap(this.logResponse), catchError(this.catchError));
      // resolve() {
      //   this.cactus.getData(this.constants.urlLoadAbusesList).subscribe(res => {
      //     if (res.status === 401 || res.status === 403) {
      //       this.cactus.requestSignInQuestUserAdmin()
      //       .subscribe(data => {
      //         if (data.status === 1) {
      //           const tokenGuestAdmin = data.data.token;
      //           localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
      //           this.resolve();
      //         } else {
      //           console.log('error login guest admin');
      //         }
      //       });
      //     } else {
      //       return this.cactus.getData(this.constants.urlLoadAbusesList).pipe(catchError(error => {
      //               return of('data not available at this time' + error);
      //       }));
      //     }
      //   }, error => {
      //     return of('data not available at this time' + error);
      //   });
      // }
}
