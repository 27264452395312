import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {MatPaginator, MatSort, MatDialog, MatTableDataSource} from '@angular/material';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { ConstantsService } from 'src/app/utils/constants.service';
import { CactusService } from 'src/app/api/cactus.service';
import { User } from 'src/app/models/user.model';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { AlertService } from 'src/app/utils/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit, AfterViewInit {
  list: any;
  users: any;
  displayedColumns: string[] = ['username', 'email', 'gender', 'country', 'created', 'actions'];
  dataSource: MatTableDataSource<any>;
  data: User[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private urls: ConstantsService, private cactus: CactusService, private dialog: MatDialog,
    private strAlert: AlertService, private router: Router) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getAllUser();
  }

  getAllUser() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          const datatosend = {
            page: this.paginator.pageIndex + 1,
            perPage: ''
          };
          return this.cactus.LoadDataWithPagination(this.urls.urlLoadUsers, datatosend);
        }),

        map(data => {
          if (data.status === 1) {

                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    // this.resultsLength = data.data.total_count;
                    this.users = data.data.users;
                    console.log('gggggggg' , this.users)
                    return data.data.users;
                  }  else if (data.status === 401 || data.status === 403) {
                    this.cactus.requestSignInQuestUserAdmin()
                    .subscribe(res => {
                      if (res.status === 1) {
                        const tokenGuestAdmin = res.data.token;
                        localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
                        this.ngAfterViewInit();
                      } else {
                          console.log('error login guest admin');
                      }
                    });
                }
                     }),
                      catchError(() => {
                  this.isLoadingResults = false;
                  this.isRateLimitReached = true;
                  return observableOf([]);
                         })
    ).subscribe(data => {
        this.resultsLength = data.length;
        this.data = data;
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });

         }

  deleteUser(user: User) {
    this.openAlertDialog(
      'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
      'Attention',
      true,
      '#000000',
      '#FFEB3B',
      'delete',
      user);
  }

  private openAlertDialog(message, title, showAction, color, bg_color, action, item) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        if (action === 'delete') {
          this.confirmDelete(item);
        }
      }
    });
  }

  confirmDelete(user: User) {
    this.cactus.pushData(this.urls.urlArchiverUser, {userId: user._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.confirmDelete(user);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
          setTimeout (() => {
                 this.getAllUser();
                }, 1000);
        this.data = this.data.filter(item => item._id !== user._id);

        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
      } else {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  toUserProfile(row: User) {
    this.router.navigate(['/main/user/', row._id]);
  }

}
