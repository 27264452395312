import { ConstantsService } from './../../utils/constants.service';
import { CactusService } from 'src/app/api/cactus.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import {merge, of as observableOf, empty} from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../../utils/alert.service';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';

@Component({
  selector: 'app-owners-list',
  templateUrl: './owners-list.component.html',
  styleUrls: ['./owners-list.component.css']
})
export class OwnersListComponent implements OnInit, AfterViewInit {
  isAuth = false;
  itemowner: any;
  datatosendowner: { itemId: any; };
  acceptRowId: any;
  owners: any;
  editRowId: any = '';
  isHidden = true;
  item_owner: any;
  user_owner: any;
  datatosend: { userId: any; itemId: any; owner: boolean };
  userId: any;
  title_item: any;
  pageOwner: void;
  resultsLength: any;
  data: any;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['fullName', 'email', 'phone', 'item' , 'actions', 'remove'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private cactus: CactusService, private dialog: MatDialog, private urls: ConstantsService, private router: Router,
    private strAlert: AlertService) {
     }

  ngOnInit() {

    this.getAllOwners();
  }

ngAfterViewInit() {

}


applyFilter(filterValue: string) {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;
}

getAllOwners() {

  this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        const datatosend = {
          page: this.paginator.pageIndex + 1,
           perPage: ''
        };
        return this.cactus.LoadDataWithPagination(this.urls.urlGelAllOwners, datatosend);
      }),

      map(data => {
        if (data.status === 1) {
                  this.owners = data.data.owners;
                  return data.data.owners;
                }
      })
  ).subscribe(data => {
      // this.result = data;
      this.resultsLength = data.length;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

 }


submitEdit(item) {
  this.openAlertDialoge(
    'Êtes-vous sûr de vouloir accepter cette demande ?',
    'Attention',
    true,
    '#000000',
    '#FFEB3B',
    item,
    'edit');
}

cancelEdit(item) {
  this.openDialoge(
    'Êtes-vous sûr de vouloir refuser cette demande ?',
    'Attention',
    true,
    '#000000',
    '#FFEB3B',
    item,
    'edit');
}
private openDialoge(message, title, showAction, color, bg_color, item, action) {
  const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
    disableClose: true,
    panelClass: 'custom-dialog',
    data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
  });
  alertDialogRef.afterClosed().subscribe(result => {
    if (result === 'Confirm') {
        this.CancelRequest(item);
    }
  });
}
private openAlertDialoge(message, title, showAction, color, bg_color, item, action) {
  const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
    disableClose: true,
    panelClass: 'custom-dialog',
    data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
  });
  alertDialogRef.afterClosed().subscribe(result => {
    if (result === 'Confirm') {
        this.isHidden = true;
        this.AcceptRequest(item);
    }
  });
}
AcceptRequest(row) {

  this.acceptRowId = row._id;
  this.datatosend = {
    owner : true,
    userId : row.userId._id,
    itemId : row.itemId._id,

  };
       this.cactus.pushData(this.urls.urlAddOwner , this.datatosend).subscribe(res => {
        if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestUser = data.data.token;
              localStorage.setItem('tokenGuestUser', tokenGuestUser);
            } else {
              console.log('error login guest admin');
            }
          });
        } else {
          if (res.status === 2) {
            this.isHidden = true;
            this.strAlert.error(res.message, {closeButton: true, progressBar: true});
          }
          if (res.status === 1) {
            this.isHidden = true;
            this.strAlert.success(res.message, {closeButton: true, progressBar: true});
          }
        }

  });

}

CancelRequest(row) {

  this.editRowId = row._id;
  this.datatosend = {
    owner : false,
    userId : row.userId._id,
    itemId : row.itemId._id,

  };
       this.cactus.pushData(this.urls.urlCancelOwner , this.datatosend).subscribe(res => {
        if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestUser = data.data.token;
              localStorage.setItem('tokenGuestUser', tokenGuestUser);
            } else {
              console.log('error login guest admin');
            }
          });
        } else {
          if (res.status === 1) {
            this.isHidden = true;
            this.strAlert.success(res.message, {closeButton: true, progressBar: true});
          }
        }

  });
}
RemoveOwner(row) {
  this.itemowner = row.item;

  console.log(this.editRowId);
    this.openDialogeOwner(
      'Êtes-vous sûr de vouloir supprimer le propriétaire ?',
      'Attention',
      true,
      '#000000',
      '#FFEB3B',
      row,
      'edit');
}





RemoveOwnerRequest(row) {
  this.editRowId = row.itemId._id;
  this.datatosendowner = {
    itemId : row.itemId._id,
   };
       this.cactus.pushData(this.urls.urlRemoveOwnerItem , this.datatosendowner).subscribe(res => {
        if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestUser = data.data.token;
              localStorage.setItem('tokenGuestUser', tokenGuestUser);
            } else {
              console.log('error login guest admin');
            }
          });
        } else {
          if (res.status === 1) {
            this.isHidden = true;
            this.isAuth = true ;
             setTimeout (() => {
                     this.getAllOwners();
                    }, 3000);
                  console.log('Hello from functionNr1 after setTimeout in code' ,  this.getAllOwners());
            this.strAlert.success(res.message, {closeButton: true});
          }
        }

  });
}

private openDialogeOwner(message, title, showAction, color, bg_color, item, action) {
  const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
    disableClose: true,
    panelClass: 'custom-dialog',
    data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
  });
  alertDialogRef.afterClosed().subscribe(result => {
    if (result === 'Confirm') {
        this.isHidden = true;
        this.isAuth = true;
        this.RemoveOwnerRequest(item);
    }
  });
}




toOwnerDetails(row) {
  this.router.navigate(['/main/user/', row.userId.id]);
}
toOwnerItem(row) {
  this.router.navigate(['/main/item/', row.itemId._id]);
}
}
