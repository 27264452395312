import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AlertService } from '../../utils/alert.service';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';
import { RSResponse } from '../../models/rs-response.model';

@Component({
  selector: 'app-list-item-by-category',
  templateUrl: './list-item-by-category.component.html',
  styleUrls: ['./list-item-by-category.component.css']
})
export class ListItemByCategoryComponent implements AfterViewInit {

  resultsLength: any;
  items: any;
  rsResponse?: RSResponse;
  displayedColumns = ['name', 'creator' , 'nbr_eval' ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private dialog: MatDialog, private strAlert: AlertService,
              private cactus: CactusService, private constants: ConstantsService) {
                this.rsResponse = this.activatedRoute.snapshot.data.value;
                this.items = this.rsResponse.data.items;
                this.dataSource = new MatTableDataSource(this.items);
                console.log(this.router);
              }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.resultsLength = this.items.length;
  }

  applyFilter(filterValue: string) {
   filterValue = filterValue.trim();
   filterValue = filterValue.toLowerCase();
   this.dataSource.filter = filterValue;
  }
  toItemDetails(row) {
    this.router.navigate(['/main/item/', row._id]);
  }


}
