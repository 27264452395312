import { ConstantsService } from '../../utils/constants.service';
import { AlertService } from '../../utils/alert.service';
import { Router } from '@angular/router';
import { CactusService } from '../../api/cactus.service';
import { RSItem } from './../../models/rs-item.model';
import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { Admin } from '../../models/admin.model';
import { PopupAddAdminComponent } from '../popup-add-admin/popup-add-admin.component';

@Component({
  selector: 'app-administrateurs',
  templateUrl: './administrateurs.component.html',
  styleUrls: ['./administrateurs.component.css']
})
export class AdministrateursComponent implements OnInit {

  datatosend: any ;
  adminArray: any;
  constants: any;
  users: any;
  displayedColumns: string[] = ['login', 'email', 'actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  data: Admin[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  constructor(private urls: ConstantsService, private dialog: MatDialog , private cactus: CactusService,
    private router: Router , private strAlert: AlertService) {}

  ngOnInit() {

  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.getAllAdmin();
  }

  getAllAdmin() {

    this.cactus.pushData(this.urls.urlLoadAdmin , this.datatosend).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestUser = data.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
          } else {
            console.log('error login guest admin');
          }
        });
      } else {
        if (res.status === 1) {
          this.data = res.data.admins;
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.dataSource = new MatTableDataSource(this.data );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }

  });

   }
  addNewAdmin() {
          this.openDialogAddNewAdmin();
  }

  openDialogAddNewAdmin() {
  this.dialog.open(PopupAddAdminComponent);
  }

  deleteAdmin(admin: Admin) {
    this.openAlertDialog(
      'Êtes-vous sûr de vouloir supprimer cet admin?',
      'Attention',
      true,
      '#000000',
      '#FFEB3B',
      'delete',
      admin);
  }

  private openAlertDialog(message, title, showAction, color, bg_color, action, admin) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        if (action === 'delete') {
          this.confirmDelete(admin);
        }
      }
    });
  }
  confirmDelete(admin: Admin) {
    this.cactus.pushData(this.urls.urlDeleteAdmin, {_id: admin.id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.confirmDelete(admin);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
      } else {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

}
