import { map, tap, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RSResponse } from '../models/rs-response.model';
import { ConstantsService } from '../utils/constants.service';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CactusService {

  constructor(private http: HttpClient, private constants: ConstantsService) { }

  public getData(url: string) {
    const tokenGuestAdmin = localStorage.getItem('tokenGuestAdmin');
    if (tokenGuestAdmin == null) {
      this.requestSignInQuestUserAdmin()
      .subscribe(res => {
        if (res.status === 1) {
          const newTokenGuestAdmin = res.data.token;
          localStorage.setItem('tokenGuestAdmin', newTokenGuestAdmin);
          const httpHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            'X-Access-Token': newTokenGuestAdmin
          });
          return this.http
          .get(url, { headers: httpHeaders })
          .pipe(map(this.extractData), tap(this.logResponse), catchError(this.catchError));
        } else {
            console.log('error login guest admin');
        }
      });
    } else {
      const httpHeaders = new HttpHeaders ({
        'Content-Type': 'application/json',
        'X-Access-Token': tokenGuestAdmin
      });
      return this.http
        .get(url, { headers: httpHeaders })
        .pipe(map(this.extractData), tap(this.logResponse), catchError(this.catchError));
    }

  }

  public pushData(url: string, data: any) {
    const tokenGuestAdmin = localStorage.getItem('tokenGuestAdmin');
    if (tokenGuestAdmin == null) {
      this.requestSignInQuestUserAdmin()
      .subscribe(res => {
        if (res.status === 1) {
          const newTokenGuestAdmin = res.data.token;
          localStorage.setItem('tokenGuestAdmin', newTokenGuestAdmin);
          const httpHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            'X-Access-Token': newTokenGuestAdmin
          });
          return this.http
            .post(url, data, { headers: httpHeaders })
            .pipe(map(this.extractData), tap(this.logResponse), catchError(this.catchError));
        } else {
            console.log('error login guest admin');
        }
      });
    } else {
      const httpHeaders = new HttpHeaders ({
        'Content-Type': 'application/json',
        'X-Access-Token': tokenGuestAdmin
      });
      return this.http
        .post(url, data, { headers: httpHeaders })
        .pipe(map(this.extractData), tap(this.logResponse), catchError(this.catchError));
    }

  }

  public LoadDataWithPagination(url: string, data: any): Observable<RSResponse> {
    const tokenGuestAdmin = localStorage.getItem('tokenGuestAdmin');
    if (tokenGuestAdmin == null) {
      this.requestSignInQuestUserAdmin()
      .subscribe(res => {
        if (res.status === 1) {
          const newTokenGuestAdmin = res.data.token;
          localStorage.setItem('tokenGuestAdmin', newTokenGuestAdmin);
          const httpHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            'X-Access-Token': newTokenGuestAdmin
          });
          return this.http.post<RSResponse>(url, data, { headers: httpHeaders });
        } else {
            console.log('error login guest admin');
        }
      });
    } else {
      const httpHeaders = new HttpHeaders ({
        'Content-Type': 'application/json',
        'X-Access-Token': tokenGuestAdmin
      });
      return this.http.post<RSResponse>(url, data, { headers: httpHeaders });
    }

  }

  private catchError(error: any) {
    console.log(error);
    return throwError(error || 'server error.');
  }
  private logResponse(res: any) {
    console.log(res);
  }
  private extractData(res: RSResponse) {
    return JSON.parse(JSON.stringify(res));
  }

  public requestSignInQuestUserAdmin() {
    const dataToSendFoGuestUserAdmin = {
      email: 'rankstop-admin@steeringit.com',
      password: 'rankstop-admin',
    };
    return this.http
      .post(this.constants.urlLoginGuestUserAdmin, dataToSendFoGuestUserAdmin)
      .pipe(map(this.extractData), tap(this.logResponse), catchError(this.catchError));
  }

  public pushDataSignInAdmin(url: string, data: any) {
    return this.http
      .post(url, data)
      .pipe(map(this.extractData), tap(this.logResponse), catchError(this.catchError));
  }

}
