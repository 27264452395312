import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';

import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';

@Injectable()
export class CriteriaResolver implements Resolve<any> {
  constructor(private cactus: CactusService, private constants: ConstantsService) {}

  resolve() {
    return this.cactus.getData(this.constants.urlCriteriasList).pipe(catchError(error => {
      return of('data not available at this time' + error);
    }));
    // return this.cactus.getData();
  }
}
