import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import {MatPaginator, MatSort, MatDialog, MatTableDataSource} from '@angular/material';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { ConstantsService } from 'src/app/utils/constants.service';
import { CactusService } from 'src/app/api/cactus.service';
import { UserArchive } from 'src/app/models/user-Archive.model';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { AlertService } from 'src/app/utils/alert.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-users-archive-list',
  templateUrl: './users-archive-list.component.html',
  styleUrls: ['./users-archive-list.component.css']
})
export class UsersArchiveListComponent implements OnInit , AfterViewInit  {

  users: any;
  isBtnDisabled: boolean;
  userSelected = [];
  displayedColumns: string[] = ['checkbox', 'username', 'email', 'gender', 'country', 'created'];
  dataSource: MatTableDataSource<any>;
  data: UserArchive[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  checked = false;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private urls: ConstantsService, private cactus: CactusService, private dialog: MatDialog,
    private strAlert: AlertService, private router: Router) {}
    ngOnInit() {
      this.checked = false;
    }

    ngAfterViewInit() {
      this.getAllUserArchive();
    }
    getAllUserArchive() {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            const datatosend = {
              page: this.paginator.pageIndex + 1,
              perPage: ''
            };
            return this.cactus.LoadDataWithPagination(this.urls.urlLoadArchiveUsers, datatosend);
          }),
          map(data => {
            if (data.status === 1) {

                      // Flip flag to show that loading has finished.
                      this.isLoadingResults = false;
                      this.isRateLimitReached = false;
                      // this.resultsLength = data.data.total_count;
                      this.users = data.data.users;
                      return data.data.users;
                    }  else if (data.status === 401 || data.status === 403) {
                      this.cactus.requestSignInQuestUserAdmin()
                      .subscribe(res => {
                        if (res.status === 1) {
                          const tokenGuestAdmin = res.data.token;
                          localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
                          this.ngAfterViewInit();
                        } else {
                            console.log('error login guest admin');
                        }
                      });
                  }
                       }),
                        catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                           })
      ).subscribe(data => {
          this.resultsLength = data.length;
          this.data = data;
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

     }
    addCheck(user: UserArchive) {
    if (user.selected === true) {
      this.userSelected.push(user);
    } else if (user.selected === false) {
      for (let i = 0 ; i < this.userSelected.length; i++) {
        if ( this.userSelected[i] === user) {
        delete this.userSelected[i];
        this.userSelected.length = this.userSelected.length - 1;
        }
     }
  }
  if ( this.userSelected.length <= 0) {
    this.isBtnDisabled = true;
    if ( this.userSelected.length !== this.data.length) {
      this.checked = false;
    }
  } else {
    this.isBtnDisabled = false;
    if ( this.userSelected.length === this.data.length) {
         this.checked = true;
    }
  }

    }


    checkAllUser(event) {
      if (event === true ) {
        for (let i = 0 ; i < this.data.length; i++) {
          const users = this.data[i];
          users.selected = true;
          this.userSelected.push(users);
          this.isBtnDisabled = false;
    }
      } else if (event === false) {
        for (let i = 0 ; i < this.data.length; i++) {
          const users = this.data[i];
             users.selected = false;
             delete(this.userSelected[i]);
             this.userSelected.length = 0;
        }
        this.isBtnDisabled = true;
      }

    }


    deleteUsers() {
      const dataToSend = {
        _id: this.userSelected,
      };
      this.cactus.pushData(this.urls.urlDeleteArchiveUser, dataToSend).subscribe(res => {
        if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestAdmin = data.data.token;
              localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            } else {
              console.log('error login guest admin');
            }
          });
        } else if (res.status === 1) {
           setTimeout (() => {
                   this.getAllUserArchive();
                  }, 1000);
            this.strAlert.success('delete items Succefuly', {closeButton: true, progressBar: true});
          } else {
            this.strAlert.error('Error ', {closeButton: true, progressBar: true});
          }
    });
  }

    applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    }
    toUserProfile(row: UserArchive) {
      this.router.navigate(['/main/userarch/', row._id]);
    }
  }
