import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bar-chart-animated',
  templateUrl: './bar-chart-animated.component.html',
  styleUrls: ['./bar-chart-animated.component.css']
})
export class BarChartAnimatedComponent implements OnInit {
  @Input() nbrgoogle: number;
  @Input() nbrfacebook: number;
  @Input() nbrApp: number;
  @Input() totaluser: number;

  public id = Math.floor(Math.random() * (999999 - 100000)) + 100000;

  public options = {
    'hideCredits': true,
    'type': 'serial',
    'theme': 'light',
    'animation': true,
    'dataProvider': [ {
      'country': 'Bad',
      'visits': 50.5,
      'color': '#f44336'
    }, {
      'country': 'Neutral',
      'visits': 10,
      'color': '#FFA000'
    }, {
      'country': 'Good',
      'visits': 20,
      'color': '#4CAF50'
    } ],
    'valueAxes': [{
      // 'unit': '%',
      'gridColor': '#FFFFFF',
        'gridAlpha': 0.2,
      'dashLength': 0,
      'maximum': this.totaluser,
      'minimum': 0,
    }],

    'gridAboveGraphs': true,
    'startDuration': 0,
    'graphs': [ {
      'balloonText': '[[category]]: <b>[[value]]</b>',
      'fillColorsField': 'color',
      'fillAlphas': 0.8,
      'lineAlpha': 20,
      'type': 'column',
      'valueField': 'visits',
      'fixedColumnWidth': 30
    } ],
    'chartCursor': {
      'categoryBalloonEnabled': false,
      'cursorAlpha': 0,
      'zoomable': false
    },
    'categoryField': 'country',
    'categoryAxis': {
      'gridPosition': 'start',
      'gridAlpha': 0,
      'tickPosition': 'start',
      'tickLength': 20
    },
    'export': {
      'enabled': true
    }
  };


  constructor() { }

  ngOnInit() {
    this.options.dataProvider = [ {
      'country': 'Google',
      'visits': this.nbrgoogle,
      'color': '#e51c23'
    }, {
      'country': 'Facebook',
      'visits': this.nbrfacebook,
      'color': '#5b7dff'
    }, {
      'country': 'Rankstop',
      'visits': this.nbrApp,
      'color': '#ff9800'
    } ];

  }

}
