import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data } from '../../../../node_modules/@angular/router';
import { RSResponse } from '../../models/rs-response.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '../../../../node_modules/@angular/material';
import { AlertService } from '../../utils/alert.service';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { Abuse } from 'src/app/models/abuse.model';
import { PopupAddAbuseComponent } from '../popup-add-abuse/popup-add-abuse.component';

@Component({
  selector: 'app-abuses-list',
  templateUrl: './abuses-list.component.html',
  styleUrls: ['./abuses-list.component.css']
})
export class AbusesListComponent implements AfterViewInit {

  name: { en: string; de: string; fr: string; };
  dataSourceAbuse: MatTableDataSource<any>;
  filtred_abuse = [];
  old_de: string;
  old_en: string;
  old_fr: string;
  name_de: string;
  name_en: string;
  name_fr: string;
  update_abuse: boolean;
  old_editRowId: any = '';
  id: any;
  editRowId: any = '';
  data: any;
  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['nameFR', 'nameEN', 'nameDE', 'actions', 'update'];
  // dataSource: MatTableDataSource<any>;
  rsResponse?: RSResponse;
  abusesArray?: Abuse[];
  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog, private strAlert: AlertService,
  private cactus: CactusService, private constants: ConstantsService) {

    this.rsResponse = this.activatedRoute.snapshot.data.value;
    this.abusesArray = this.rsResponse.data;
    this.dataSource = new MatTableDataSource(this.abusesArray);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.update_abuse = false;

  }

  addNewAbuse() {
    const dialogRef = this.dialog.open(PopupAddAbuseComponent, {
      disableClose: true,
      panelClass: 'custom-dialog'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.result === 'success') {
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
        this.abusesArray.push(res.abuse);
        this.dataSource.data = this.abusesArray;
      } else if (res.result === 'error') {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }

  deleteAbuse(abuse: Abuse) {
    let message = 'Êtes-vous sûr de vouloir désactiver ce raison de signal?';
    if (!abuse.isActivate) {
      message = 'Êtes-vous sûr de vouloir réactiver ce raison de signal?';
    }
    this.openAlertDialog(
      message,
      'Attention',
      true,
      '#000000',
      '#FFEB3B',
      'delete',
      abuse);
  }

  private openAlertDialog(message, title, showAction, color, bg_color, action, item) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        if (action === 'delete') {
          this.confirmDelete(item);
        }
      }
    });
  }

  confirmDelete(abuse: Abuse) {
    this.cactus.pushData(this.constants.urlAbuseStatus, {ReportAbuseId: abuse._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.confirmDelete(abuse);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        const index = this.abusesArray.indexOf(abuse);
        this.abusesArray[index].isActivate = !this.abusesArray[index].isActivate;
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
      } else {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }


  applyFilter(filterValue: string) {
console.log(filterValue);
        const data_abuse = this.abusesArray.filter(x =>
           x.names.fr.trim().toLowerCase().includes(filterValue.trim().toLowerCase())
          || x.names.en.trim().toLowerCase().includes(filterValue.trim().toLowerCase())
          || x.names.de.trim().toLowerCase().includes(filterValue.trim().toLowerCase()));

         this.dataSource.data = data_abuse;


      }




editData(item: Abuse ) {
    this.editRowId = item._id;
    this.update_abuse = true;
      this.update_abuse = true;
      this.editRowId = item._id;
      this.name_fr = item.names.fr;
      this.name_en = item.names.en;
      this.name_de = item.names.de;
      if (this.old_editRowId === this.editRowId) {
        this.old_fr = this.name_fr;
        this.old_en = this.name_en;
        this.old_de = this.name_de;
      } else {
        for (let i = 0; i < this.abusesArray.length ; i++ ) {
          if (this.abusesArray[i]._id === this.old_editRowId) {
            this.abusesArray[i].names.fr = this.old_fr;
            this.abusesArray[i].names.en = this.old_en;
            this.abusesArray[i].names.de = this.old_de;
          }
        }

        this.old_editRowId = item._id;
        this.old_fr = item.names.fr;
        this.old_en = item.names.en;
        this.old_de = item.names.de;
    }
  }
  cancelEditValue(abuse: Abuse) {
    for (let i = 0; i < this.abusesArray.length ; i++ ) {
      if (this.abusesArray[i]._id === abuse._id) {
    this.abusesArray[i].names.en = this.name_en,
    this.abusesArray[i].names.fr = this.name_fr,
    this.abusesArray[i].names.de = this.name_de;
    }
  }
      this.old_editRowId = abuse._id;
      this.editRowId = '';
  }


  confirmEdit(item) {
    const datatosend = {
    id : item._id,
    english: item.names.en,
    french: item.names.fr ,
    deutsch: item.names.de,
};
this.cactus.pushData(this.constants.urlUpdateAbuse, datatosend).subscribe(res => {
  if (res.status === 401 || res.status === 403) {
    this.cactus.requestSignInQuestUserAdmin()
    .subscribe(data => {
      if (data.status === 1) {
        const tokenGuestAdmin = data.data.token;
        localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
      } else {
        console.log('error login guest admin');
      }
    });
  } else if (res.status === 1) {
   this.editRowId = '';
   console.log('message sucess form server ' + res.message );
  } else {
   console.log('message d\'error form server ' + res.message );
  }
    });
  }
  submitEdit(item: Abuse) {
    this.openAlertDialoge(
      'Êtes-vous sûr de vouloir confirmer cette modification?',
      'Attention',
      true,
      '#000000',
      '#FFEB3B',
      item,
      'edit');
  }
  private openAlertDialoge(message, title, showAction, color, bg_color, item, action) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
          this.confirmEdit(item);
      }
    });
  }
}

