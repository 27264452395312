import { Comment } from './../../models/comment.model';
import { Component, OnInit } from '@angular/core';
import { RSResponse } from 'src/app/models/rs-response.model';
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';
import {ModalDismissReasons, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { CactusService } from 'src/app/api/cactus.service';
import { ConstantsService } from 'src/app/utils/constants.service';
import { User } from '../../models/user.model';

  @Component({
    selector: 'app-archive-item-details',
    templateUrl: './archive-item-details.component.html',
    styleUrls: ['./archive-item-details.component.css']
  })
  export class ArchiveItemDetailsComponent implements OnInit {
    [x: string]: any;
    item_id: any;
    itemId: string;
    commentsFiltred: any;
    comments: any;
    resultpix: any;
    rsResponse?: RSResponse;
    item?: any;
    perPage = 10;

    // commentaires loader
    isLoadingComment = true;
    isCommentReached = false;
    listComment: any = [];
    pageComment = 1;
    countPageComment = 0;
    // photos loader
    isLoadingPix = true;
    isPixReached = false;
    listPix: any = [];
    pagePix = 1;
    countPagePix = 0;
    constructor(private router: Router, private activatedRoute: ActivatedRoute,
      private cactus: CactusService, private urls: ConstantsService) {
        this.router.events.subscribe((event) => {
          if (this.router.url.slice(0, 15) === '/main/itemarch/') {
             this.itemId = this.router.url.slice(15);
        }
      });
    }

    ngOnInit() {
      this.ArchiveItemDetails(this.itemId);
    }

    tabChanged(index) {
      if (index === 1  && this.pageComment === 1) {
        this.loadComments();
      } else if (index === 2 && this.pagePix === 1) {
        this.loadPix();
      }
    }


    ArchiveItemDetails(itemId) {
      const dataToSendUserEval = {
        itemId: itemId,
      };
      this.cactus.pushData(this.urls.urlArchiveItemDetails, dataToSendUserEval).subscribe(res => {
        if (res.status === 1) {
                this.item = res.data;
        } else if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestAdmin = data.data.token;
              localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            } else {
              console.log('error login guest admin');
            }
          });
        }
      });
    }

    loadComments() {
      const dataToSendUserComment = {
        perPage: this.perPage,
        page: this.pageComment,
        itemId: this.itemId,
      };
      this.isLoadingComment = true;
      this.cactus.pushData(this.urls.urlGetCommentsByItem, dataToSendUserComment).subscribe(res => {
        if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestAdmin = data.data.token;
              localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
              this.loadComments();
            } else {
              console.log('error login guest admin');
            }
          });
        } else  {
          this.isLoadingComment = false;
          this.isCommentReached = false;
          this.listComment = res.data.comment;
          this.countPageComment = res.data.pages;
          if (this.listComment.length > 0) {
            this.pageComment++;
          }
        }
      }, error => {
        console.log(error);
        this.isLoadingComment = false;
        this.isCommentReached = true;
      });
    }

  loadPix() {
    this.isLoadingPix = true;
    const dataToSendUserPicture = {
      perPage: this.perPage,
      page: this.pagePix,
      itemId: this.itemId,
    };
    this.cactus.pushData(this.urls.urlGetPixByItem, dataToSendUserPicture).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.loadPix();
          } else {
            console.log('error login guest admin');
          }
        });

      } else {
        this.isLoadingPix = false;
        this.isPixReached = false;
        this.listPix = this.listPix.concat(res.data.picture);
        this.countPagePix = res.data.pages;
        if (this.listPix.length > 0) {
          this.pagePix++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingPix = false;
      this.isPixReached = true;
    });
  }


  onSelect(_id) {
    this.cactus.pushData(this.urls.urlUserProfile, { id: _id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestUser = data.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
            this.loadItemCreated();
          } else {
            console.log('error login guest admin');
          }
        });
      } else {
        this.item = res.status;
        if (res.status === 1) {
          this.router.navigate(['/main/user/', _id]);
        } else {
          this.router.navigate(['/main/userarch/', _id]);
        }
        // incrémenter l'index de la page courante
        }

});
}
  }
