import { CriteriaComponent } from '../components/criteria/criteria.component';
import { CategoriesComponent } from '../components/categories/categories.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { LoginComponent } from '../components/login/login.component';
import { UsersListComponent } from '../components/users-list/users-list.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { Routes } from '@angular/router';
import { MainNavComponent } from '../components/main-nav/main-nav.component';
import { CategoriesResolver } from '../components/categories/categories.resolver';
import { CriteriaResolver } from '../components/criteria/criteria.resolver';
import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service';
import { CategoryDetailsComponent } from '../components/category-details/category-details.component';
import { CategoryDetailsResolver } from '../components/category-details/category-details.resolver';
import { ItemsListComponent } from '../components/items-list/items-list.component';
import { AbusesListComponent } from '../components/abuses-list/abuses-list.component';
import { AbusesResolver } from '../components/abuses-list/abuses.resolver';
import { UserProfileComponent } from '../components/user-profile/user-profile.component';
import { ItemDetailsComponent } from '../components/item-details/item-details.component';
import { UserProfileResolver } from '../components/user-profile/user-profile.resolver';
import { ItemDetailsResolver } from '../components/item-details/item-details.resolver';
import { UsersArchiveListComponent } from '../components/users-archive-list/users-archive-list.component';
import { ItemsArchiveListComponent } from '../components/items-archive-list/items-archive-list.component';
import { ArchiveUserProfilComponent } from '../components/archive-user-profil/archive-user-profil.component';
import { ArchiveUserProfileResolver } from '../components/archive-user-profil/archive-user-profil.resolver';
import { ArchiveItemDetailsComponent } from '../components/archive-item-details/archive-item-details.component';
import { AdministrateursComponent } from '../components/administrateurs/administrateurs.component';
import { OwnersListComponent } from '../components/owners-list/owners-list.component';
import { ListItemByCategoryComponent } from '../components/list-item-by-category/list-item-by-category.component';
import { ListItemByCategoryResolver } from '../components/list-item-by-category/list-item-by-category.resolver';
export const routes: Routes = [
  {
    path: 'main',
    component: MainNavComponent,
    children: [
        { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
        { path: 'users', component: UsersListComponent, canActivate: [AuthGuard] },
        { path: 'owners', component: OwnersListComponent, canActivate: [AuthGuard] },
        { path: 'admins', component: AdministrateursComponent, canActivate: [AuthGuard] },
        { path: 'items', component: ItemsListComponent, canActivate: [AuthGuard] },
        { path: 'usersArchive', component: UsersArchiveListComponent, canActivate: [AuthGuard] },
        { path: 'itemsArchive', component: ItemsArchiveListComponent, canActivate: [AuthGuard] },
        { path: 'categories', component: CategoriesComponent, resolve: { value: CategoriesResolver }, canActivate: [AuthGuard]},
        { path: 'abuses', component: AbusesListComponent, resolve: { value: AbusesResolver }, canActivate: [AuthGuard]},
        { path: 'category/:id', component: CategoryDetailsComponent, resolve: { value: CategoryDetailsResolver },
          canActivate: [AuthGuard]},
        { path: 'userarch/:id', component: ArchiveUserProfilComponent, resolve: { value: ArchiveUserProfileResolver },
         canActivate: [AuthGuard]},
         { path: 'item/:id', component: ItemDetailsComponent, resolve: { value: ItemDetailsResolver },
         canActivate: [AuthGuard]},
          { path: 'user/:id', component: UserProfileComponent, resolve: { value: UserProfileResolver },
          canActivate: [AuthGuard]},
        { path: 'listitem/:id/:name', component: ListItemByCategoryComponent, resolve: { value: ListItemByCategoryResolver },
          canActivate: [AuthGuard]},
          { path: 'itemarch/:id', component: ArchiveItemDetailsComponent, canActivate: [AuthGuard]},
        { path: 'criteria', component: CriteriaComponent, resolve: {value: CriteriaResolver}, canActivate: [AuthGuard]},
        { path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [AuthGuard] }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: '**', component: PageNotFoundComponent}
];
