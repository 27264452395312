import { Component, OnInit, Inject } from '@angular/core';
import { Criteria } from '../../models/criteria.model';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { CactusService } from '../../api/cactus.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material';
import { ConstantsService } from '../../utils/constants.service';
import { stringify } from 'querystring';

@Component({
  selector: 'app-popup-add-category',
  templateUrl: './popup-add-category.component.html',
  styleUrls: ['./popup-add-category.component.css']
})
export class PopupAddCategoryComponent implements OnInit {

  criteriasList: Criteria[];
  criteriasSelected = new FormControl();
  categoryNameFR = '';
  categoryNameEN = '';
  categoryNameDE = '';
  isLocationEnabled = false;
  isbarcodeEnabled = false;

  constructor(private cactus: CactusService, private dialogRef: MatDialogRef<PopupAddCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private constants: ConstantsService) {
      this.criteriasList = data.criteriasList;
    }

  ngOnInit() {
  }

  onCloseConfirm() {
    const criteriaList = [];
    for (const criteria of this.criteriasSelected.value) {
      criteriaList.push({criteria: criteria._id, isActive: true});
    }
    this.cactus.pushData(this.constants.urlAddCategory, {french: this.categoryNameFR, english: this.categoryNameEN, deutsch: this.categoryNameDE, criterias: criteriaList, location: this.isLocationEnabled, barcode: this.isbarcodeEnabled}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.onCloseConfirm();
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.dialogRef.close({ result: 'success', categoriesList: res.data, message: res.message });
      } else {
        this.dialogRef.close({result: 'error', message: res.message});
      }
    });
  }
  onCloseCancel() {
    this.categoryNameEN = '';
    this.categoryNameFR = '';
    this.categoryNameDE = '';
    this.criteriasSelected.reset();
  }

}
