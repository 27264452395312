import { Category } from './../../models/category.model';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RSResponse } from '../../models/rs-response.model';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '../../../../node_modules/@angular/material';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';
import { AlertService } from '../../utils/alert.service';
import { PopupAddCategoryComponent } from '../popup-add-category/popup-add-category.component';
import { Criteria } from '../../models/criteria.model';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList} from '@angular/cdk/drag-drop';
import clonedeep from 'lodash.clonedeep';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements AfterViewInit {
  newdatasource = []
  test: any;
  data_eval: any;
  numberEval: any;
  test_event = false
  items: any;
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  // displayedColumns = ['name', 'location', 'nbr_c_obligatoires', 'actions'];
  displayedColumns = ['name', 'location', 'barcode', 'nbr_c_obligatoires', 'nbr_eval', 'more' ];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  rsResponse?: RSResponse;
  categoriesArray?: Category[];
  categoryresult = [];
  data: { this: any; i: number; };

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cactus: CactusService,
  private constants: ConstantsService, private strAlert: AlertService, private dialog: MatDialog) {

    this.rsResponse = this.activatedRoute.snapshot.data.value;
    this.categoriesArray = this.rsResponse.data;
    this.dataSource = new MatTableDataSource(this.categoriesArray);
  }

  ngAfterViewInit() {
    this.dataSource.data = this.dataSource.data
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  drop(event: CdkDragDrop<string[]>) {
    
  this.test_event = true;
  if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    for(var i=0; i <this.dataSource.data.length ; i++){

      this.getlistCategorybyorder(this.dataSource.data[i]._id , i)
      // this.dataSource.data[i].order = i;
    }
    // // updates moved data and table, but not dynamic if more dropzones
    this.newdatasource = clonedeep(this.dataSource.data);
    this.dataSource.data = this.newdatasource;
     
  }

 
  getlistCategorybyorder(_id ,index) {
    var data ={
      _id : _id,
      index: index
    }
    this.cactus.pushData(this.constants. urlgetlistcategorybyorder, data).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.addNewCategory();
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
          
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  navigateToDetails(row: Category) {
    this.router.navigate(['/main/category/', row._id]);
  }

  getitemsbyCategory(row) {
   
    this.router.navigate(['/main/listitem/', row._id , row.name]);
  }

  // getEvalbycategory(id) {
  //   const datatosend = {
  //     categoryId : id
  //   };
  //   this.cactus.pushData(this.constants.urlGetEvalByCategory, datatosend).subscribe(res => {
  //     if (res.status === 401 || res.status === 403) {
  //       this.cactus.requestSignInQuestUserAdmin()
  //       .subscribe(data => {
  //         if (data.status === 1) {
  //           const tokenGuestAdmin = data.data.token;
  //           localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
  //         } else {
  //           console.log('error login guest admin');
  //         }
  //       });
  //     } else if (res.status === 1) {
  //       this.data_eval = res.data.category;
  //       this.items = res.data.items.length;
  //       this.numberEval = res.data.numberEval;
  //   }
  //   });
  // }


  addNewCategory() {
    this.cactus.getData(this.constants.urlCriteriasList).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.addNewCategory();
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        if (res.data.length > 0) {
          this.openDialogAddNewCategory(res.data);
        } else {
          this.strAlert.info('Il n\'y a pas de critère disponible', {closeButton: true, progressBar: true});
        }
      }
    });
  }

  openDialogAddNewCategory(criteriasList: Criteria[]) {
    const dialogRef = this.dialog.open(PopupAddCategoryComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { criteriasList: criteriasList }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.result === 'success') {
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
        this.categoriesArray.push(res.categoriesList);
        this.dataSource.data = this.categoriesArray;
      } else if (res.result === 'error') {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }

  deleteCategory(category: Category) {
    this.openAlertDialog(
      'Êtes-vous sûr de vouloir supprimer cette catégorie?',
      'Attention',
      true,
      '#000000',
      '#FFEB3B',
      'delete',
      category);
  }

  private openAlertDialog(message, title, showAction, color, bg_color, action, item) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        if (action === 'delete') {
          this.confirmDelete(item);
        }
      }
    });
  }

  confirmDelete(category: Category) {
    this.cactus.pushData(this.constants.urlDeleteCategory, {_id: category._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.confirmDelete(category);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.categoriesArray = this.categoriesArray.filter(item => item._id !== category._id);
        this.dataSource.data = this.categoriesArray;
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
      } else {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }



}
