import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';
import { PopupUpdateCategoryComponent } from '../popup-update-category/popup-update-category.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-popup-update-item',
  templateUrl: './popup-update-item.component.html',
  styleUrls: ['./popup-update-item.component.css']
})
export class PopupUpdateItemComponent implements OnInit {

  item: any;
  Description: any;
  title: any;
  itemid: any;
  constructor(private router: Router, private route: ActivatedRoute , private cactus: CactusService , private constants: ConstantsService,
    private dialogRef: MatDialogRef<PopupUpdateCategoryComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.item = data;
  }

  ngOnInit() {
     this.itemid = this.item.iditem._id;
     this.title = this.item.iditem.title;
     this.Description = this.item.iditem.description;

  }
  onCloseConfirm() {
    const datatosend = {
      _id : this.itemid,
      description : this.Description,
      title : this.title,
    };
    this.cactus.pushData(this.constants.urlUpdateItemBackofficeh, datatosend).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.dialogRef.close({ result: 'success', res: res.data.result, message: res.message });
      } else {
        this.dialogRef.close({result: 'error', message: res.message});
      }
    });
  }

  onCloseCancel() {
      this.Description = '';
      this.title = '';
  }

}
