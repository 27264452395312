import { Component, OnInit, Inject } from '@angular/core';
import { CactusService } from '../../api/cactus.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '../../../../node_modules/@angular/material';
import { ConstantsService } from '../../utils/constants.service';

@Component({
  selector: 'app-popup-add-single-criteria',
  templateUrl: './popup-add-single-criteria.component.html',
  styleUrls: ['./popup-add-single-criteria.component.css']
})
export class PopupAddSingleCriteriaComponent implements OnInit {

  criteriaNameFR = '';
  criteriaNameEN = '';
  criteriaNameDE = '';

  constructor(private dialogRef: MatDialogRef<PopupAddSingleCriteriaComponent>, private cactus: CactusService,
    @Inject(MAT_DIALOG_DATA) public data: any, private constants: ConstantsService) {}

  ngOnInit() {
  }

  onCloseConfirm() {
    this.cactus.pushData(this.constants.urlAddNewCriteria, {french: this.criteriaNameFR, english: this.criteriaNameEN, deutsch: this.criteriaNameDE}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.onCloseConfirm();
          } else {
            console.log('error login guest admin');
          }
        });

      } else if ( res.status === 1 ) {
        this.dialogRef.close({ result: 'success', criteriaList: res.data, message: res.message });
      } else {
        this.dialogRef.close({result: 'error', message: res.message});
      }
    });
  }
  onCloseCancel() {
    this.criteriaNameFR = '';
    this.criteriaNameEN = '';
    this.criteriaNameDE = '';
  }

}
