import { PopupFeedbackComponent } from './../popup-feedback/popup-feedback.component';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { Criteria } from '../../models/criteria.model';
import { RSResponse } from '../../models/rs-response.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '../../../../node_modules/@angular/material';
import { PopupAddSingleCriteriaComponent } from '../popup-add-single-criteria/popup-add-single-criteria.component';
import { AlertService } from '../../utils/alert.service';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';

@Component({
  selector: 'app-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.css']
})
export class CriteriaComponent implements AfterViewInit {

  update_crit: boolean;
  old_crit_de: string;
  old_crit_en: string;
  old_crit_fr: string;
  old_editRowId: any = '';
  name_crit_de: string;
  name_crit_en: string;
  name_crit_fr: string;
  nameDE: any;
  nameEN: any;
  nameFR: any;
  editRowId: any = '';
  inputvalue = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // displayedColumns = ['nameFR', 'nameEN', 'nameDE', 'actions'];
  displayedColumns = ['nameFR', 'nameEN', 'nameDE', 'actions'];

  dataSource;

  rsResponse?: RSResponse;
  criteriasArray?: Criteria[] = null;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog, private strAlert: AlertService,
  private cactus: CactusService, private constants: ConstantsService) {
    this.rsResponse = this.activatedRoute.snapshot.data.value;
    this.criteriasArray = this.rsResponse.data;
    this.dataSource = new MatTableDataSource(this.criteriasArray);
  }

  ngAfterViewInit() {
    this.update_crit = false;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    for ( let i = 0 ; i < this.criteriasArray.length ; i++) {
    this.nameDE = this.criteriasArray[i].name.de;
    this.nameEN = this.criteriasArray[i].name.en;
    this.nameFR = this.criteriasArray[i].name.fr;
  }
}
applyFilter(filterValue: string) {
  const data_criteria = this.criteriasArray.filter(x =>
     x.name.fr.trim().toLowerCase().includes(filterValue.trim().toLowerCase())
     || x.name.en.trim().toLowerCase().includes(filterValue.trim().toLowerCase())
     || x.name.de.trim().toLowerCase().includes(filterValue.trim().toLowerCase())
  );

  this.dataSource.data = data_criteria;


}
  addNewCriteria() {
    const dialogRef = this.dialog.open(PopupAddSingleCriteriaComponent, {
      disableClose: true,
      panelClass: 'custom-dialog'
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.result === 'success') {
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
        this.criteriasArray = res.criteriaList;
        this.dataSource.data = this.criteriasArray;
      } else if (res.result === 'error') {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }

  deleteCriteria(criteria: Criteria) {
    this.openAlertDialog(
      'Êtes-vous sûr de vouloir supprimer ce critère?',
      'Attention',
      true,
      '#000000',
      '#FFEB3B',
      'delete',
      criteria);
  }

  private openAlertDialog(message, title, showAction, color, bg_color, action, item) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        if (action === 'delete') {
          this.confirmDelete(item);
        }
      }
    });
  }

  confirmDelete(criteria: Criteria) {
    this.cactus.pushData(this.constants.urlDeleteCriteria, {_id: criteria._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.confirmDelete(criteria);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.criteriasArray = this.criteriasArray.filter(item => item._id !== criteria._id);
        this.dataSource.data = this.criteriasArray;
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
      } else {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }



  confirmEdit(item) {
    const datatosend = {
      _id : item._id ,
      french: item.name.fr,
      english: item.name.en,
      deutsch: item.name.de ,
 };

     this.cactus.pushData(this.constants.urlUpdateCriteria, datatosend).subscribe(res => {
       if (res.status === 401 || res.status === 403) {
         this.cactus.requestSignInQuestUserAdmin()
         .subscribe(data => {
           if (data.status === 1) {
             const tokenGuestAdmin = data.data.token;
             localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
           } else {
             console.log('error login guest admin');
           }
         });
       } else if (res.status === 1) {
        this.editRowId = '';
        console.log('message sucess form server ' + res.message );
       } else {
        console.log('message d\'error form server ' + res.message );
       }
     });
   }

submitEdit(item: Criteria) {
  this.openAlertDialoge(
    'Êtes-vous sûr de vouloir confirmer cette modification?',
    'Attention',
    true,
    '#000000',
    '#FFEB3B',
    item,
    'edit');
}
  editData(item: Criteria ) {
    this.update_crit = true;
    this.editRowId = item._id;
    this.name_crit_fr = item.name.fr;
    this.name_crit_en = item.name.en;
    this.name_crit_de = item.name.de;
    if (this.old_editRowId === this.editRowId) {
      this.old_crit_fr = this.name_crit_fr;
      this.old_crit_en = this.name_crit_en;
      this.old_crit_de = this.name_crit_de;
    } else {
      for (let i = 0; i < this.criteriasArray.length ; i++ ) {
        if (this.criteriasArray[i]._id === this.old_editRowId) {
          this.criteriasArray[i].name.fr = this.old_crit_fr;
          this.criteriasArray[i].name.en = this.old_crit_en;
          this.criteriasArray[i].name.de = this.old_crit_de;
        }
      }
      this.old_editRowId = item._id;
      this.old_crit_fr = item.name.fr;
      this.old_crit_en = item.name.en;
      this.old_crit_de = item.name.de;
  }
}

cancelEditValue(item: Criteria) {
  for (let i = 0; i < this.criteriasArray.length ; i++ ) {
    if (this.criteriasArray[i]._id === item._id) {
   this.criteriasArray[i].name.fr  = this.name_crit_fr;
   this.criteriasArray[i].name.en = this.name_crit_en;
   this.criteriasArray[i].name.de = this.name_crit_de;
    }
  }
  this.old_editRowId = item._id;
  this.editRowId = '';
}
private openAlertDialoge(message, title, showAction, color, bg_color, item, action) {
  const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
    disableClose: true,
    panelClass: 'custom-dialog',
    data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
  });
  alertDialogRef.afterClosed().subscribe(result => {
    if (result === 'Confirm') {
        this.confirmEdit(item);
    }
  });
}
}
