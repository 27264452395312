import { RSItem } from './../../models/rs-item.model';
import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MatCheckbox } from '@angular/material';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap, findIndex} from 'rxjs/operators';
import { ConstantsService } from 'src/app/utils/constants.service';
import { CactusService } from 'src/app/api/cactus.service';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { AlertService } from 'src/app/utils/alert.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-items-archive-list',
  templateUrl: './items-archive-list.component.html',
  styleUrls: ['./items-archive-list.component.css']
})
export class ItemsArchiveListComponent implements OnInit, AfterViewInit {



  result: any;
  checked = false;
  items: any;
  displayedColumns: string[] = ['checkbox', 'title', 'category',  'evaluations', 'created'];
  dataSource: MatTableDataSource<any>;
  data: RSItem[] = [];
   resultsLength ;
  isBtnDisabled = false;
  isLoadingResults = true;
  itemSelected = [];
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private urls: ConstantsService, private cactus: CactusService, private dialog: MatDialog,
    private strAlert: AlertService, private router: Router) {}
    ngOnInit() {
      this.checked = false;
      this.isBtnDisabled = true;
    }

    ngAfterViewInit() {
         this.getAllArchiveItem();
    }

    getAllArchiveItem() {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            const datatosend = {
              page: this.paginator.pageIndex + 1,
               perPage: ''
            };
            return this.cactus.LoadDataWithPagination(this.urls.urlLoadArchiveItems, datatosend);
          }),

          map(data => {
            if (data.status === 1) {

                      // Flip flag to show that loading has finished.
                      this.isLoadingResults = false;
                      this.isRateLimitReached = false;
                      // this.resultsLength = data.data.total_count;
                      this.items = data.data.items;
                      return data.data.items;
                    } else if (data.status === 401 || data.status === 403) {
                              this.cactus.requestSignInQuestUserAdmin()
                              .subscribe(res => {
                                if (res.status === 1) {
                                  const tokenGuestAdmin = res.data.token;
                                  localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
                                  this.ngAfterViewInit();
                                } else {
                                    console.log('error login guest admin');
                                }
                              });
                          }
          }),
          catchError(() => {
            this.isLoadingResults = false;
            this.isRateLimitReached = true;
            return observableOf([]);
          })
      ).subscribe(data => {
          this.result = data;
          this.resultsLength = data.length;
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
           }

    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    addCheck(item: RSItem ) {
      if (item.selected === true) {
        this.itemSelected.push(item);
      } else if (item.selected === false) {
        for (let i = 0 ; i < this.itemSelected.length; i++) {
          if ( this.itemSelected[i] === item) {
          delete this.itemSelected[i];
          this.itemSelected.length = this.itemSelected.length - 1;
        }
       }
    }
    if (this.itemSelected.length !== this.resultsLength ) {
      this.checked = false;
    } else if (this.itemSelected.length === this.resultsLength ) {
      this.checked = true;
}
    if ( this.itemSelected.length === 0) {
      this.isBtnDisabled = true;
    } else {
      this.isBtnDisabled = false;
    }
    }


    checkAllItem(event) {
      if (event === true) {
        this.isBtnDisabled = false;
        for (let i = 0 ; i < this.result.length; i++) {
              const items = this.result[i];
              items.selected = true;
              this.itemSelected.push(items);
        }

      } else if (event === false) {
        this.isBtnDisabled = true;
        for (let i = 0 ; i < this.result.length; i++) {
          const items = this.result[i];
          items.selected = false;
          delete this.itemSelected[i];
          this.itemSelected.length = 0;
    }
    }
      }


      deleteActivities() {
        const dataToSend = {
          _id: [this.itemSelected],
        };
        this.cactus.pushData(this.urls.urlDeleteArchiver, dataToSend).subscribe(res => {
          if (res.status === 401 || res.status === 403) {
            this.cactus.requestSignInQuestUserAdmin()
            .subscribe(data => {
              if (data.status === 1) {
                const tokenGuestAdmin = data.data.token;
                localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
              } else {
                console.log('error login guest admin');
              }
            });
          } else if (res.status === 1) {
              setTimeout (() => {
                     this.getAllArchiveItem();
                    }, 1000);
              this.strAlert.success('delete items Succefuly', {closeButton: true});
            } else {
              this.strAlert.error(res.message, {closeButton: true, progressBar: true});
            }
      });
    }

toItemDetails(row: RSItem) {
  this.router.navigate(['/main/itemarch/', row._id]);
}
}
