import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent {

  title = 'Rank Stop';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private dialog: MatDialog) {}

  logout() {
    this.openAlertDialog(
      'Voulez-vous vraiment déconnecter?',
      'Attention',
      true,
      '#000000',
      '#FFEB3B');
  }

  private openAlertDialog(message, title, showAction, color, bg_color) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        localStorage.removeItem('token');
        this.router.navigate(['/login']);
      }
    });
  }

  }
