import { Component, OnInit, Inject } from '@angular/core';
import { CactusService } from '../../api/cactus.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '../../../../node_modules/@angular/material';
import { ConstantsService } from '../../utils/constants.service';
import { Criteria } from '../../models/criteria.model';
import { FormControl } from '../../../../node_modules/@angular/forms';

@Component({
  selector: 'app-popup-add-criterias',
  templateUrl: './popup-add-criterias.component.html',
  styleUrls: ['./popup-add-criterias.component.css']
})
export class PopupAddCriteriasComponent implements OnInit {

  criteriasList: Criteria[];
  criteriasSelected = new FormControl();
  categoryId;

  constructor(private cactus: CactusService, private dialogRef: MatDialogRef<PopupAddCriteriasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private constants: ConstantsService) {
      this.criteriasList = data.criteriasList;
      console.log(this.criteriasList);
      this.categoryId = data._id;
    }

  ngOnInit() {
  }

  addCriterias() {
    const criteriaList = [];
    for (const criteria of this.criteriasSelected.value) {
      criteriaList.push({criteria: criteria._id, isActive: true});
    }
    this.cactus.pushData(this.constants.urlAddCriteriasToCategory, {_id: this.categoryId, listCriteriasId: criteriaList}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.addCriterias();
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.dialogRef.close({ result: 'success', data: this.criteriasSelected.value, message: res.message });
      } else {
        this.dialogRef.close({result: 'error', message: res.message});
      }
    });
  }

}
