import { Component, OnInit } from '@angular/core';
import { RSResponse } from 'src/app/models/rs-response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CactusService } from 'src/app/api/cactus.service';
import { ConstantsService } from 'src/app/utils/constants.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  rsResponse?: RSResponse;
  userInfo?: any;
  perPage = 10;


  // page created loader
  isLoadingPC = true;
  isPCReached = false;
  listPC: any = [];
  pagePC = 1;
  countPagePC = 0;
  // page owned loader
  isLoadingPO = true;
  isPOReached = false;
  listPO: any = [];
  pagePO = 1;
  countPagePO = 0;
  // page followed loader
  isLoadingPF = true;
  isPFReached = false;
  listPF: any = [];
  pagePF = 1;
  countPagePF = 0;
  listItemsByUsereval: any;
  TotalPagesItemEval: any;

  constructor(private activatedRoute: ActivatedRoute, private cactus: CactusService, private urls: ConstantsService,
    private router: Router) { }

  ngOnInit() {
    this.rsResponse = this.activatedRoute.snapshot.data.value;
    this.userInfo = this.rsResponse.data;
    console.log('tghgdgsdh', this.userInfo);
    this.loadItemCreated();
    this.ListItemByUserEval(10, 1) 

  }
  getColorProgressBar(note) {
    if (note === 0 || note === 1) {
      return 'progress-bar-danger';
    } if (note === 2 || note === 3) {
      return 'progress-bar-warning';
    } if (note === 4 || note === 5) {
      return 'progress-bar-success';
    }
  }
  tabChanged(index) {
    if (index === 0 && this.pagePC === 1) {
      this.loadItemCreated();
    } else if (index === 1  && this.pagePF === 1) {
      this.loadItemFollowed();
    } else if (index === 2 && this.pagePO === 1) {
      this.loadItemOwned();
    }
    else if (index === 3)  {
      this.ListItemByUserEval(10 ,1);
    }
  }

  loadItemOwned() {
    this.isLoadingPO = true;
    this.cactus.pushData(this.urls.urlItemOwned, {perPage: this.perPage, page: this.pagePO, userId: this.userInfo.user._id, lang: 'fr'}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestUser = data.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
            this.loadItemOwned();
          } else {
            console.log('error login guest admin');
          }
        });

      } else {
        this.isLoadingPO = false;
        this.isPOReached = false;
        console.log(res.data.items);
        this.listPO = this.listPO.concat(res.data.items);
        this.countPagePO = res.data.pages;
        // incrémenter l'index de la page courante
        if (res.data.items.length > 0) {
          this.pagePO++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingPO = false;
      this.isPOReached = true;
    });
  }

  ListItemByUserEval(perPage, page) {
    const dataToSendUserEval = {
      userId: this.userInfo.user._id,
      perPage: perPage,
      page: page,
    };
    console.log(dataToSendUserEval)
    this.cactus.pushData(this.urls.urlGetItemAllByUserEvaluated, dataToSendUserEval).subscribe(res => {
      if (res.status === 1) {
        this.listItemsByUsereval = res.data.items;
        console.log('tessst' ,this.listItemsByUsereval);
        this.TotalPagesItemEval = res.data.pages;
      } 
    });
  }



  loadItemFollowed() {
    this.isLoadingPF = true;
    this.cactus.pushData(this.urls.urlItemFollowed, {perPage: this.perPage, page: this.pagePF, userId: this.userInfo.user._id, lang: 'fr'}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestUser = data.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
            this.loadItemFollowed();
          } else {
            console.log('error login guest admin');
          }
        });

      } else {
        this.isLoadingPF = false;
        this.isPFReached = false;
        this.listPF = this.listPF.concat(res.data.items);
        this.countPagePF = res.data.pages;
        // incrémenter l'index de la page courante
        if (res.data.items.length > 0) {
          this.pagePF++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingPF = false;
      this.isPFReached = true;
    });
  }

  loadItemCreated() {
    this.isLoadingPC = true;
    this.cactus.pushData(this.urls.urlItemCreated, {perPage: this.perPage, page: this.pagePC, userId: this.userInfo.user._id, lang: 'fr'}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestUser = data.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
            this.loadItemCreated();
          } else {
            console.log('error login guest admin');
          }
        });

      } else {
        this.isLoadingPC = false;
        this.isPCReached = false;
        this.listPC = this.listPC.concat(res.data.items);
        this.countPagePC = res.data.pages;
        // incrémenter l'index de la page courante
        if (res.data.items.length > 0) {
          this.pagePC++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingPC = false;
      this.isPCReached = true;
    });
  }

  toItemDetails(item) {
    this.router.navigate(['/main/item/', item.itemDetails._id]);
  }
}
