import { CactusService } from './../../api/cactus.service';
import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConstantsService } from '../../utils/constants.service';

@Injectable({
  providedIn: 'root'
})
export class ListItemByCategoryResolver {

  constructor(private cactus: CactusService, private constants: ConstantsService) {}


  resolve(route: ActivatedRouteSnapshot) {
    return this.cactus.pushData(this.constants.urlgetlistitembycategory, {'category': route.paramMap.get('id')}).pipe(catchError(error => {
        return of('data not available at this time');
      }));
    // return this.cactus.getUser(route.paramMap.get('id'));
  }
}

