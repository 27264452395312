import { PopupUpdateCategoryComponent } from '../popup-update-category/popup-update-category.component';
import { Component, OnInit } from '@angular/core';
import { RSResponse } from '../../models/rs-response.model';
import { Criteria } from '../../models/criteria.model';
import { ActivatedRoute, Router, NavigationStart } from '../../../../node_modules/@angular/router';
import { Category } from '../../models/category.model';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { AlertService } from '../../utils/alert.service';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';
import { PopupAddCriteriasComponent } from '../popup-add-criterias/popup-add-criterias.component';
import { CategoryDetails } from 'src/app/models/category-details.model';
import { CriteriaDetails } from 'src/app/models/criteria-details.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.css']
})
export class CategoryDetailsComponent implements OnInit {


  numberEval: any;
  items: any;
  subscription: Subscription;
  browserRefresh = false;
  param1: string;
  param: any;
  catId: any;
  rsResponse?: RSResponse;
  category?: CategoryDetails;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private dialog: MatDialog, private strAlert: AlertService,
              private cactus: CactusService, private constants: ConstantsService) {
              }

  ngOnInit() {
    this.rsResponse = this.activatedRoute.snapshot.data.value;
    this.category = this.rsResponse.data;
    this.catId = this.category._id;
    this.getEvalbycategory();


  }

  sumActiveCriteria() {
    let sum = 0;
    for (const criteria of this.category.criterias) {
      if (criteria.isActive) {
        sum++;
      }
    }
    return sum;
  }
  deleteCriteria(criteria: CriteriaDetails) {
    if (criteria.isActive) {
      if (this.sumActiveCriteria() > 3) {
        this.openAlertDialog(
          'Êtes-vous sûr de vouloir désactiver ce critère?',
          'Attention',
          true,
          '#000000',
          '#FFEB3B',
          'delete',
          criteria);
      } else {
        this.openAlertDialog(
          'Chaque catégorie doit contenir au minimum 3 critères actifs.',
          'Attention',
          false,
          '#FFffff',
          '#F44336');
      }
    } else {
      this.openAlertDialog(
        'Êtes-vous sûr de vouloir résactiver ce critère?',
        'Attention',
        true,
        '#000000',
        '#FFEB3B',
        'delete',
        criteria);
    }
  }

  private openAlertDialog(message, title, showAction, color, bg_color, action?, item?) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        if (action === 'delete') {
          this.confirmDelete(item);
        }
      }
    });
  }

  confirmDelete(item: CriteriaDetails) {
    this.cactus.pushData(this.constants.urlManageCategoryCriteria, {idCat: this.category._id, idCri: item.criteria._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.confirmDelete(item);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
        const index = this.category.criterias.indexOf(item);
        this.category.criterias[index].isActive = !this.category.criterias[index].isActive;
      } else {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }


  addNewCriteria() {
    const listIdCriterias = [];
    for ( const criteria of this.category.criterias) {
      listIdCriterias.push(criteria.criteria._id);
    }
    this.cactus.pushData(this.constants.urlAvailableCriteriasList, {listId: listIdCriterias}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.addNewCriteria();
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        if (res.data.length > 0) {
          this.openDialogAddNewCriteria(res.data);
        } else {
          this.strAlert.info('Il n\'y a pas de critère disponible', {closeButton: true, progressBar: true});
        }
      }
    });
  }

  openDialogAddNewCriteria(criteriasList: Criteria[]) {
    const dialogRef = this.dialog.open(PopupAddCriteriasComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { criteriasList: criteriasList, _id: this.category._id }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res.result === 'success') {
        this.strAlert.success(res.message, {closeButton: true, progressBar: true});
        for (const criteria of res.data) {
          this.category.criterias.push({criteria: { _id: criteria._id, name: criteria.name.fr}, isActive: true});
        }
      } else if (res.result === 'error') {
        this.strAlert.error(res.message, {closeButton: true, progressBar: true});
      }
    });
  }
  UpdateCategory() {
            this.openDialogUpdateCategory(
              this.category);
    }

    openDialogUpdateCategory (category) {
      const DialogRef = this.dialog.open(PopupUpdateCategoryComponent, {
          disableClose: true,
          panelClass: 'custom-dialog',
          data: { category: category}


      });
      DialogRef.afterClosed().subscribe(result => {
        this.category.name = result.res.name;
        this.category.location = result.res.location;
        this.category.barcode = result.res.barcode;
      });
    }

    getEvalbycategory() {
      const datatosend = {
        categoryId : this.catId
      };
      this.cactus.pushData(this.constants.urlGetEvalByCategory, datatosend).subscribe(res => {
        if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestAdmin = data.data.token;
              localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            } else {
              console.log('error login guest admin');
            }
          });
        } else if (res.status === 1) {
          this.items = res.data.items.length;
          this.numberEval = res.data.numberEval;
        }
      });
    }

}
