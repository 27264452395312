import { RSItem } from './../../models/rs-item.model';
import {Component, ViewChild, OnInit, AfterViewInit} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog , MatSortModule  } from '../../../../node_modules/@angular/material';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { ConstantsService } from 'src/app/utils/constants.service';
import { CactusService } from 'src/app/api/cactus.service';
import { PopupFeedbackComponent } from '../popup-feedback/popup-feedback.component';
import { AlertService } from 'src/app/utils/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.css']
})
export class ItemsListComponent implements OnInit, AfterViewInit {

  items: any;
  result: void;
  // displayedColumns: string[] = ['title', 'category', 'country', 'lastDateView', 'created', 'actions'];
  displayedColumns: string[] = ['title', 'category', 'country', 'lastDateView', 'created', 'eval' , 'actions'];
  dataSource: MatTableDataSource<any>;
  data: RSItem[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private urls: ConstantsService, private dialog: MatDialog , private cactus: CactusService,
    private router: Router , private strAlert: AlertService) {}


  ngOnInit() {
  }

  ngAfterViewInit() {
      this.getAllItem();
    // If the user changes the sort order, reset back to the first page.

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;


  }
  getAllItem() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          const datatosend = {
            page: this.paginator.pageIndex + 1,
             perPage: ''
          };
          return this.cactus.LoadDataWithPagination(this.urls.urlLoadItems, datatosend);
        }),

        map(data => {
          if (data.status === 1) {

                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    // this.resultsLength = data.data.total_count;
                    this.items = data.data.items;
                    return data.data.items;
                  }
        })
    ).subscribe(data => {
        this.result = data;
        this.resultsLength = data.length;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });

         }


  deleteItem(item: RSItem) {
    this.openAlertDialog(
      'Êtes-vous sûr de vouloir supprimer cet item?',
      'Attention',
      true,
      '#000000',
      '#FFEB3B',
      'delete',
      item);
  }
  private openAlertDialog(message, title, showAction, color, bg_color, action, item) {
    const alertDialogRef = this.dialog.open(PopupFeedbackComponent, {
      disableClose: true,
      panelClass: 'custom-dialog',
      data: { message: message, title: title, showAction: showAction, color: color, bg_color: bg_color }
    });
    alertDialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        if (action === 'delete') {
          this.confirmDelete(item);
        }
      }
    });
  }
  confirmDelete(item: RSItem) {
    this.cactus.pushData(this.urls.urlArchiverItem, {itemId: item._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.confirmDelete(item);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
          setTimeout (() => {
                 this.getAllItem();
                }, 500);
        this.strAlert.success(res.message, {closeButton: true});
      } else {
        this.strAlert.error(res.message, {closeButton: true});
      }
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  toItemDetails(row: RSItem) {
    this.router.navigate(['/main/item/', row._id]);
  }

}
