import { Router } from '@angular/router';
import { Component, OnInit, Input, ViewChild , NgZone, AfterViewInit } from '@angular/core';
import { CactusService } from '../../api/cactus.service';
import { UsersListComponent } from '../users-list/users-list.component';
import { MatPaginator, MatSort } from '@angular/material';
import { ConstantsService } from '../../utils/constants.service';
import { BarChartAnimatedComponent } from '../bar-chart-animated/bar-chart-animated.component';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit , AfterViewInit {



  value: number;
  idcountry: any;
  country: any;
  resultat: number;
  list: any;
  topscore: void;
  getAllcountry: any;
  nbrtotaleval: any;
  nbreval: any;
  width: any;
  datacountry: any;
  getAllCatecory: any;
  currentDate: Date;
  nbr_pictures: any;
  Pictures: any;
  comments: any;
  nbr_comments: any;
  nbr_eval: any;
  evals: any;
  nbr_user: any;
  resdata: any;
  nbr_item_up: any;
  itemsresult: any;
  resultItemsbyTopRank: any;
  listItemsTopRank: any;
  htmlToAddd: string;
  htmlToAdd: string;
  inscription: any;
  resultsLengthitem: any;
  resultsLength: any;
// **************** Charts *************************************************************************************
optionsForChartUsersByInscriptions: any = {};

// private chart: am4charts.XYChart;

  constructor(private urls: ConstantsService, private cactus: CactusService , private router: Router , private zone: NgZone) {}
  // cards = [
  //   {  title: 'Card 1' , cols: 2, rows: 1},
  //   { title: 'Card 2' , cols: 1, rows: 1  },
  //   { title: 'Card 3', cols: 1, rows: 1 },
    // { title: 'Card 4', cols: 1, rows: 2 },
    // { title: 'Card 5', cols: 1, rows: 1 }
  // ];

  ngOnInit() {
    this.getCountrybyuser();
    this.getCategorybyTopEval();
    this.getAllEval();
    this.getAllComments();
    this.getAllPictures();
    this.getAllUser();
    this.getAllItem();
    this.NbrInscriptionUser();
    this.getListItemByTopRank();

  }

  ngAfterViewInit() {
    this.getItemLastcreatedwithmonth();
    this.getlastPictureswithmonth();
    this.getUserLastcreatedwithmonth();
    this.getlastCommentswithmonth();
    this.getlastevalwithmonth();



    const chart = am4core.create('chartdiv', am4maps.MapChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.geodata = am4geodata_worldLow;
    chart.projection = new am4maps.projections.Miller();
    const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    const polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}: {value.value.formatNumber(\'#.\')}';
    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: am4core.color('#47BAC1'),
      max: am4core.color('#47BAC1')
    });
    polygonSeries.useGeodata = true;
    polygonSeries.mapPolygons.template.events.on('over', event => {
      handleHover(event.target);
    });
    polygonSeries.mapPolygons.template.events.on('hit', event => {
      handleHover(event.target);
    });

    function handleHover(mapPolygon) {
      if (!isNaN(mapPolygon.dataItem.value)) {
      } else {
      }
    }
    polygonSeries.mapPolygons.template.strokeOpacity = 0.4;
    polygonSeries.mapPolygons.template.events.on('out', event => {
    });

    chart.zoomControl = new am4maps.ZoomControl();
    chart.zoomControl.valign = 'bottom';
   const homeButton = new am4core.Button();
   homeButton.events.on('hit', function() {
    polygonSeries.show();
     chart.goHome();
   });
   homeButton.icon = new am4core.Sprite();
   homeButton.padding(7, 5, 7, 5);
   homeButton.width = 30;
   homeButton.icon.path = 'M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8';
   homeButton.marginBottom = 10;
   homeButton.parent = chart.zoomControl;
   homeButton.insertBefore(chart.zoomControl.plusButton);
    const listcountry: any = [];
               for (let i = 0; i < this.getAllcountry.length ; i++ ) {
      this.idcountry = this.getAllcountry[i]._id;
      this.value = this.getAllcountry[i].users ;
                 listcountry.push( {id : this.idcountry.toUpperCase()  , value: this.value});

                 }

    polygonSeries.data = listcountry;
    polygonSeries.exclude = ['AQ'];
  }



  verifyDifficulty(nbr) {
    const result = nbr;
    return result;
  }

  getCountrybyuser() {
    this.cactus.pushData(this.urls.urlgetCountryByNbrUser , {lang: ''}).subscribe(res => {
        if (res.status === 1) {
          this.getAllcountry = res.data;
          const tokenGuestUser = res.data.token;
          localStorage.setItem('tokenGuestUser', tokenGuestUser);
        } else if (res.status === 401 || res.status === 403) {
          // tslint:disable-next-line:no-shadowed-variable
          this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
          if (res.status === 1) {
            const tokenGuestUser = res.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
          }
        });
      }
    });
  }
  getCategorybyTopEval() {
    this.cactus.pushData(this.urls.urlgetCategorybyTopEval , {lang: ''}).subscribe(res => {
        if (res.status === 1) {
          this.getAllCatecory = res.data;
          const tokenGuestUser = res.data.token;
          localStorage.setItem('tokenGuestUser', tokenGuestUser);
        } else if (res.status === 401 || res.status === 403) {
          // tslint:disable-next-line:no-shadowed-variable
          this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
          if (res.status === 1) {
            const tokenGuestUser = res.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
          }
        });
      }
    });
  }

  getAllUser() {
    this.cactus.pushData(this.urls.urlLoadUsers , {page: '', perPage: ''}).subscribe(res => {
        if (res.status === 1) {
          this.resultsLength = res.data.users.length;
          const tokenGuestUser = res.data.token;
          localStorage.setItem('tokenGuestUser', tokenGuestUser);
        } else if (res.status === 401 || res.status === 403) {
          // tslint:disable-next-line:no-shadowed-variable
          this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
          if (res.status === 1) {
            const tokenGuestUser = res.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
          }
        });
      }
    });
  }

  NbrInscriptionUser() {
    this.cactus.pushData(this.urls.urlNbrInscriptionUser , {page: '', perPage: ''}).subscribe(res => {
        if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestAdmin = data.data.token;
              localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            } else {
              console.log('error login guest admin');
            }
          });
        } else if (res.status === 1)  {
          this.inscription = res.data;
        }
      });
  }


  getListItemByTopRank() {
    const dataToSendPaginationTopRank = {
      perPage: 100,
      page: 1,
      lang: localStorage.getItem('language')
    };
    this.cactus.pushData(this.urls.urlgetListItemByTopRank , dataToSendPaginationTopRank).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
          } else {
            console.log('error login guest admin');
          }
        });
      } else if (res.status === 1)  {
        this.listItemsTopRank = res.data.items.slice(0 , 10);
        this.listItemsTopRank = this.listItemsTopRank.sort(function (a, b) {
          return (a.itemDetails.evaluations < b.itemDetails.evaluations) ? 1 : (
            (b.itemDetails.evaluations < a.itemDetails.evaluations) ? -1 : 0);
        }
        );
      }
    });
  }


  getitembyscore() {

                  this.listItemsTopRank = this.listItemsTopRank.sort(function (a, b) {
                    return (a.scoreItem < b.scoreItem) ? 1 : (
                      (b.scoreItem < a.scoreItem) ? -1 : 0);
                  });

  }




  getitembynumberFollows() {
    this.listItemsTopRank = this.listItemsTopRank.sort(function (a, b) {
      return (a.numberFollows < b.numberFollows) ? 1 : (
        (b.numberFollows < a.numberFollows) ? -1 : 0);
    }
    );

  }



  getitembynumberView() {
    this.listItemsTopRank = this.listItemsTopRank.sort(function (a, b) {
      return (a.itemDetails.numberView < b.itemDetails.numberView) ? 1 : (
        (b.itemDetails.numberView < a.itemDetails.numberView) ? -1 : 0);
    }
    );

  }


  getitembyEvaluations() {
    this.listItemsTopRank = this.listItemsTopRank.sort(function (a, b) {
      return (a.itemDetails.evaluations < b.itemDetails.evaluations) ? 1 : (
        (b.itemDetails.evaluations < a.itemDetails.evaluations) ? -1 : 0);
    }
    );

  }


  toItemDetails(itemid) {
   this.router.navigate(['/main/item/', itemid]);
  }

  getAllItem() {
    this.cactus.pushData(this.urls.urlLoadItems , {page: '', perPage: ''}).subscribe(res => {
        if (res.status === 1) {
          this.resultsLengthitem = res.data.items.length;
          const tokenGuestUser = res.data.token;
          localStorage.setItem('tokenGuestUser', tokenGuestUser);
        } else if (res.status === 401 || res.status === 403) {
          // tslint:disable-next-line:no-shadowed-variable
          this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
          if (res.status === 1) {
            const tokenGuestUser = res.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
          }
        });
      }
    });
  }


 getItemLastcreatedwithmonth() {
    this.cactus.pushData(this.urls.urlgetItemLastcreatedwithmonth , {page: '', perPage: ''}).subscribe(res => {
      if (res.status === 1) {
        this.resdata = res.data.data;
        this.nbr_item_up = (this.itemsresult / this.resultsLengthitem);
        const tokenGuestUser = res.data.token;
        localStorage.setItem('tokenGuestUser', tokenGuestUser);
      } else if (res.status === 401 || res.status === 403) {
        // tslint:disable-next-line:no-shadowed-variable
        this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
        if (res.status === 1) {
          const tokenGuestUser = res.data.token;
          localStorage.setItem('tokenGuestUser', tokenGuestUser);
        }
      });
    }

    });
  }


  getUserLastcreatedwithmonth() {
    this.cactus.pushData(this.urls.urlgetUserLastcreatedwithmonth , {page: '', perPage: ''}).subscribe(res => {
      if (res.status === 1) {
        this.nbr_user = res.data.data;
        const tokenGuestUser = res.data.token;
        localStorage.setItem('tokenGuestUser', tokenGuestUser);
      } else if (res.status === 401 || res.status === 403) {
        // tslint:disable-next-line:no-shadowed-variable
        this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
        if (res.status === 1) {
          const tokenGuestUser = res.data.token;
          localStorage.setItem('tokenGuestUser', tokenGuestUser);
        }
      });
    }

    });
  }

  getAllEval() {
    this.cactus.pushData(this.urls.urlgetAllEval , {page: '', perPage: ''}).subscribe(res => {
      if (res.status === 1) {
        this.evals = res.data.total_count;
        const tokenGuestUser = res.data.token;
        localStorage.setItem('tokenGuestUser', tokenGuestUser);
      } else if (res.status === 401 || res.status === 403) {
        // tslint:disable-next-line:no-shadowed-variable
        this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
        if (res.status === 1) {
          const tokenGuestUser = res.data.token;
          localStorage.setItem('tokenGuestUser', tokenGuestUser);
        }
      });
    }

  });
  }


getlastevalwithmonth() {
  this.cactus.pushData(this.urls.urlgetEvalLastcreatedwithmonth , {page: '', perPage: ''}).subscribe(res => {
    if (res.status === 1) {
      this.nbr_eval = res.data.data;
      const tokenGuestUser = res.data.token;
      localStorage.setItem('tokenGuestUser', tokenGuestUser);
    } else if (res.status === 401 || res.status === 403) {
      // tslint:disable-next-line:no-shadowed-variable
      this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
      if (res.status === 1) {
        const tokenGuestUser = res.data.token;
        localStorage.setItem('tokenGuestUser', tokenGuestUser);
      }
    });
  }
  });
}

getlastPictureswithmonth() {
  this.cactus.pushData(this.urls.urlgetlastPictureswithmonth , {page: '', perPage: ''}).subscribe(res => {
    if (res.status === 1) {
      this.nbr_pictures = res.total_pictures;
      console.log('this.nbr_pictures' , this.nbr_pictures);
      const tokenGuestUser = res.data.token;
      localStorage.setItem('tokenGuestUser', tokenGuestUser);
    } else if (res.status === 401 || res.status === 403) {
      // tslint:disable-next-line:no-shadowed-variable
      this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
      if (res.status === 1) {
        const tokenGuestUser = res.data.token;
        localStorage.setItem('tokenGuestUser', tokenGuestUser);
      }
    });
  }
  });
}

getlastCommentswithmonth() {
  this.cactus.pushData(this.urls.urlgetlastCommentswithmonth , {page: '', perPage: ''}).subscribe(res => {
    if (res.status === 1) {
      this.nbr_comments = res.total_comments;
      const tokenGuestUser = res.data.token;
      localStorage.setItem('tokenGuestUser', tokenGuestUser);
    } else if (res.status === 401 || res.status === 403) {
      // tslint:disable-next-line:no-shadowed-variable
      this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
      if (res.status === 1) {
        const tokenGuestUser = res.data.token;
        localStorage.setItem('tokenGuestUser', tokenGuestUser);
      }
    });
  }
  });
}


getAllComments() {
  this.cactus.pushData(this.urls.urlgetAllComments , {page: '', perPage: ''}).subscribe(res => {
    if (res.status === 1) {
      this.comments = res.total_comments;
      const tokenGuestUser = res.data.token;
      localStorage.setItem('tokenGuestUser', tokenGuestUser);
    } else if (res.status === 401 || res.status === 403) {
      // tslint:disable-next-line:no-shadowed-variable
      this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
      if (res.status === 1) {
        const tokenGuestUser = res.data.token;
        localStorage.setItem('tokenGuestUser', tokenGuestUser);
      }
    });
  }
  });
}

getAllPictures() {
  this.cactus.pushData(this.urls.urlgetAllPictures , {page: '', perPage: ''}).subscribe(res => {
    if (res.status === 1) {
      this.Pictures = res.total_pictures;
      const tokenGuestUser = res.data.token;
      localStorage.setItem('tokenGuestUser', tokenGuestUser);
    } else if (res.status === 401 || res.status === 403) {
      // tslint:disable-next-line:no-shadowed-variable
      this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
      if (res.status === 1) {
        const tokenGuestUser = res.data.token;
        localStorage.setItem('tokenGuestUser', tokenGuestUser);
      }
    });
  }
  });
}

}

