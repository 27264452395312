import { Category } from './../../models/category.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CactusService } from '../../api/cactus.service';
import { ConstantsService } from '../../utils/constants.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-popup-update-category',
  templateUrl: './popup-update-category.component.html',
  styleUrls: ['./popup-update-category.component.css']
})
export class PopupUpdateCategoryComponent implements OnInit {
  param1: any;
  param: any;
  catId: any;
  category: string;
  name: any;
  location: boolean;
  barcode: boolean;
  categoryNameDE: any;
  categoryNameEN: any;
  categoryNameFR: any;
  isLocationEnabled: any;
  isbarcodeEnabled: any;


  constructor(private router: Router, private route: ActivatedRoute , private cactus: CactusService , private constants: ConstantsService,
    private dialogRef: MatDialogRef<PopupUpdateCategoryComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.category = data;
    this.catId = data.category._id;
    this.name = data.category.name;
    this.categoryNameFR = this.name.fr ;
    this.categoryNameEN = this.name.en;
    this.categoryNameDE = this.name.de ;
    this.isLocationEnabled = data.category.location;
    this.isbarcodeEnabled = data.category.barcode;
  }

  ngOnInit() {
  }
  onCloseConfirm() {
    const datatosend = {
      _id: this.catId,
      french: this.categoryNameFR,
      english: this.categoryNameEN,
      deutsch: this.categoryNameDE ,
      location: this.isLocationEnabled,
      barcode: this.isbarcodeEnabled,
    };
    this.cactus.pushData(this.constants.urlUpdateCategory, datatosend).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.dialogRef.close({ result: 'success', res: res.data.result, message: res.message });
      } else {
        this.dialogRef.close({result: 'error', message: res.message});
      }
    });
  }

  onCloseCancel() {
      this.categoryNameEN = '';
      this.categoryNameFR = '';
      this.categoryNameDE = '';
  }
}
