import { UserArchive } from 'src/app/models/user-Archive.model';
import { Component, OnInit } from '@angular/core';
import { RSResponse } from 'src/app/models/rs-response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CactusService } from 'src/app/api/cactus.service';
import { ConstantsService } from 'src/app/utils/constants.service';
@Component({
  selector: 'app-archive-user-profil',
  templateUrl: './archive-user-profil.component.html',
  styleUrls: ['./archive-user-profil.component.css']
})
export class ArchiveUserProfilComponent implements OnInit {

  item: any;
  constants: any;
  rsResponse?: RSResponse;
  archiveduserInfo?: any;
  perPage = 10;
  isLoadingPC = false;
  isPCReached = false;
  listPC: any = [];
  pagePC = 1;
  countPagePC = 0;
  // page owned loader
  isLoadingPO = true;
  isPOReached = false;
  listPO: any = [];
  pagePO = 1;
  countPagePO = 0;
  // page followed loader
  isLoadingComment = false;
  isPFReached = false;
  listPF: any = [];
  pagePF = 1;
  countPageComment = 0;

  constructor(private activatedRoute: ActivatedRoute, private cactus: CactusService, private urls: ConstantsService,
    private router: Router) { }

  ngOnInit() {
    this.rsResponse = this.activatedRoute.snapshot.data.value;
    this.archiveduserInfo = this.rsResponse.data;
    this.loadItemCreated();
  }
  tabChanged(index) {
    if (index === 0 && this.pagePC === 1) {
      this.loadItemCreated();
    } else if (index === 1  && this.pagePO === 1) {
      this.loadItemOwned();
    } else if (index === 2 && this.pagePF === 1) {
      this.loadComments();
    }
  }

  loadItemOwned() {
    this.isLoadingPO = true;
    this.cactus.pushData(this.urls.urlItemOwned, {perPage: this.perPage, page: this.pagePO, userId: this.archiveduserInfo.user._id, lang: 'fr'}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestUser = data.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
            this.loadItemOwned();
          } else {
            console.log('error login guest admin');
          }
        });

      } else {
        this.isLoadingPO = false;
        this.isPOReached = false;
        this.listPO = this.listPO.concat(res.data.items);
        this.countPagePO = res.data.pages;
        // incrémenter l'index de la page courante
        if (res.data.items.length > 0) {
          this.pagePO++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingPO = false;
      this.isPOReached = true;
    });
  }

  loadComments() {
    this.isLoadingComment = false;
    this.cactus.pushData(this.urls.urlGetArchiveCommentsByUser, {perPage: this.perPage, page: this.pagePF, userId: this.archiveduserInfo.user._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestUser = data.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
            this.loadComments();
          } else {
            console.log('error login guest admin');
          }
        });

      } else {
        this.isLoadingComment = false;
        this.isPFReached = false;
        this.listPF = res.data.comment;
        this.countPageComment = res.data.pages;
        // incrémenter l'index de la page courante
        if (res.data.comments.length > 0) {
          this.isLoadingComment = true;
          this.pagePF++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingComment = false;
      this.isPFReached = true;
    });
  }

  loadItemCreated() {
    this.isLoadingPC = false;
    this.cactus.pushData(this.urls.urlItemCreated, {perPage: this.perPage, page: this.pagePC, userId: this.archiveduserInfo.user._id, lang: 'fr'}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestUser = data.data.token;
            localStorage.setItem('tokenGuestUser', tokenGuestUser);
            this.loadItemCreated();
          } else {
            console.log('error login guest admin');
          }
        });

      } else {
        this.isLoadingPC = false;
        this.isPCReached = false;
        this.listPC = this.listPC.concat(res.data.items);
        this.countPagePC = res.data.pages;
        // incrémenter l'index de la page courante
        if (res.data.items.length > 0) {
          this.pagePC++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingPC = false;
      this.isPCReached = true;
    });
  }

  toItemDetails(item) {
    this.router.navigate(['/main/item/', item.itemDetails._id]);
  }
  onSelect(_id) {
      this.cactus.pushData(this.urls.urlItemDetails, { itemId: _id}).subscribe(res => {
        if (res.status === 401 || res.status === 403) {
          this.cactus.requestSignInQuestUserAdmin()
          .subscribe(data => {
            if (data.status === 1) {
              const tokenGuestUser = data.data.token;
              localStorage.setItem('tokenGuestUser', tokenGuestUser);
              this.loadItemCreated();
            } else {
              console.log('error login guest admin');
            }
          });
        } else {
          this.item = res.status;
          if (res.status === 1) {
            this.router.navigate(['/main/item/', _id]);
          } else {
            this.router.navigate(['/main/itemarch/', _id]);
          }
          // incrémenter l'index de la page courante
          }

});
  }
}
