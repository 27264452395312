import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {


  /* server localhost */
  baseUrl = environment.BackendUrl;

  /* server local */
  // baseUrl = 'http://rs-ws.steeringit.lc:2500/api/';

  /* server on cloud */
   //baseUrl = 'https://backend-stag.dev.steeringit.com/api/';

  // admin
  urlLoginAdmin = this.baseUrl + 'admins/signInAdmin';
  urlLoadAdmin = this.baseUrl + 'admins/getAllAdmin';
  urlAddAdmin = this.baseUrl + 'admins/addAdmin';
  urlDeleteAdmin = this.baseUrl + 'admins/deleteAdmin';

  // login guest user admin
  urlLoginGuestUserAdmin = this.baseUrl + 'users/signIn';

  // manage categories
  urlCategoriesList = this.baseUrl + 'categories/getCategory';
  urlCategoryDetails = this.baseUrl + 'categories/getCategoryByIdBackOffice';
  urlAddCategory = this.baseUrl + 'categories/addCategory';
  urlDeleteCategory = this.baseUrl + 'categories/deleteCategory';
  urlAddCriteriasToCategory = this.baseUrl + 'categories/pushNewCriterias';
  urlDeleteCriteriaFromCategory = this.baseUrl + 'categories/deleteCriteria';
  urlManageCategoryCriteria = this.baseUrl + 'categories/changeStatusCriteria';
  urlUpdateCategory = this.baseUrl + 'categories/UpdateCategory';
  urlgetlistitembycategory = this.baseUrl + 'categories/getlistitembycategory';
  urlgetlistcategorybyorder = this.baseUrl + 'categories/updateordercategory';
  urlGetItemAllByUserEvaluated = this.baseUrl + 'items/getItemAllByUserEvaluated';

  // manage criterias
  urlCriteriasList = this.baseUrl + 'criteria/getCriterias';
  urlAvailableCriteriasList = this.baseUrl + 'criteria/getAvailableCriterias';
  urlAddNewCriteria = this.baseUrl + 'criteria/addCriteria';
  urlDeleteCriteria = this.baseUrl + 'criteria/deleteCriteria';
  urlUpdateCriteria = this.baseUrl + 'criteria/updateCriteria';
  // manage abuses
  urlLoadAbusesList = this.baseUrl + 'reportAbuses/findAbuseAll';
  urlAbuseStatus = this.baseUrl + 'reportAbuses/changeStatusAbuse';
  urlAddNewAbuse = this.baseUrl + 'reportAbuses/addAbuse';
  urlUpdateAbuse = this.baseUrl + 'reportAbuses/updateAbuses';

  // users
  urlLoadArchiveUsers = this.baseUrl + 'users/getAllArchiveUser';
  urlLoadUsers = this.baseUrl + 'users/getAllUser';
  urlArchiverUser = this.baseUrl + 'users/ArchiveUser';
  urlUserProfile = this.baseUrl + 'users/userInfo';
  urlArchivedUserProfile = this.baseUrl + 'users/archiveduserinfo';
  urlDeleteArchiveUser = this.baseUrl + 'users/DeleteArchiveUser';
  urlNbrInscriptionUser = this.baseUrl + 'users/NbrInscriptionUser';
  urlgetUserLastcreatedwithmonth = this.baseUrl + 'users/getUserLastcreatedwithmonth';

  // items
  urlLoadArchiveItems = this.baseUrl + 'items/getAllArchiveItem';
  urlLoadItems = this.baseUrl + 'items/getAllItem';
  urlItemDetails = this.baseUrl + 'items/getItemByIdAndIdUser';
  urlArchiveItemDetails = this.baseUrl + 'items/getArchiveItemById';
  urlItemCreated = this.baseUrl + 'items/getItemCreatedByUser';
  urlItemFollowed = this.baseUrl + 'follows/listItemFollowersByUser';
  urlItemOwned = this.baseUrl + 'items/getItemAllByUserOwner';
  urlGelAllOwners = this.baseUrl + 'items/getAllOwners';
  urlItemComments = this.baseUrl + 'eval/getComments';
  urlItemCommentsByUser = this.baseUrl + 'eval/getCommentsByUser';
  urlGetArchiveCommentsByUser = this.baseUrl + 'eval/getArchiveCommentsByUser';
  urlGetCommentsByItem = this.baseUrl + 'eval/getCommentsByArchivedItem';
  urlItemPix = this.baseUrl + 'eval/getPictures';
  urlItemPixByUser = this.baseUrl + 'eval/getPicturesByUser';
  urlGetPixByItem = this.baseUrl + 'eval/getPicturesByArchivedItem';
  urlArchiverItem = this.baseUrl + 'items/ArchiveItem';
  urlDeleteArchiver = this.baseUrl + 'items/DeleteArchiveItem';
  urlAddOwner = this.baseUrl + 'items/AddOwnerToItem';
  urlCancelOwner = this.baseUrl + 'items/cancelOwner';
  urlGetEvalByCategory = this.baseUrl + 'items/getEvalByCategory';
  urlgetListItemByTopRank = this.baseUrl + 'items/getListItemByTopRank';
  urlgetItemLastcreatedwithmonth = this.baseUrl + 'items/getItemLastcreatedwithmonth';
  urlUpdateItemBackofficeh = this.baseUrl + 'items/UpdateItemBackoffice';
  urlRemoveOwnerItem = this.baseUrl + 'items/RemoveOwnerItem';
  // country
  getCountryByBarcode = this.baseUrl + 'items/getCountryByBarcode';
  urlgetcountry = this.baseUrl + 'categories/getcountry';
  urlgetCountryByNbrUser = this.baseUrl + 'country/getCountryByNbrUser';
  // eval
  urlgetAllEval = this.baseUrl + 'eval/getAllEval';
  urlgetEvalLastcreatedwithmonth = this.baseUrl + 'eval/getEvalLastcreatedwithmonth';
  urlgetlastPictureswithmonth = this.baseUrl + 'eval/getlastPictureswithmonth';
  urlgetlastCommentswithmonth = this.baseUrl + 'eval/getlastCommentswithmonth';
  urlgetAllComments = this.baseUrl + 'eval/getAllComments';
  urlgetAllPictures = this.baseUrl + 'eval/getAllPictures';
  urlgetAllUserByCountry = this.baseUrl + 'users/getAllUserByCountry';
  urlgetCategorybyTopEval = this.baseUrl + 'categories/getCategorybyTopEval';
  urlgetCategorybyTopItem = this.baseUrl + 'categories/getCategorybyTopItem';



  constructor() {}
}
