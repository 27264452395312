import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rs-bar-chart',
  templateUrl: './rs-bar-chart.component.html',
  styleUrls: ['./rs-bar-chart.component.css']
})
export class RsBarChartComponent implements OnInit {

  @Input() noteGood: number;
  @Input() noteNeutral: number;
  @Input() noteBad: number;

  public id = Math.floor(Math.random() * (999999 - 100000)) + 100000;

  public options = {
    'hideCredits': true,
    'type': 'serial',
    'theme': 'light',
    'dataProvider': [ {
      'country': 'Bad',
      'visits': 70.5,
      'color': '#f44336'
    }, {
      'country': 'Neutral',
      'visits': 10,
      'color': '#FFA000'
    }, {
      'country': 'Good',
      'visits': 20,
      'color': '#4CAF50'
    } ],
    'valueAxes': [ {
      'gridColor': '#FFFFFF',
      'gridAlpha': 0.2,
      'dashLength': 0
    } ],
    'gridAboveGraphs': true,
    'startDuration': 0,
    'graphs': [ {
      'balloonText': '[[category]]: <b>[[value]]</b>',
      'fillColorsField': 'color',
      'fillAlphas': 0.8,
      'lineAlpha': 0.2,
      'type': 'column',
      'valueField': 'visits',
      'fixedColumnWidth': 30
    } ],
    'chartCursor': {
      'categoryBalloonEnabled': false,
      'cursorAlpha': 0,
      'zoomable': false
    },
    'categoryField': 'country',
    'categoryAxis': {
      'gridPosition': 'start',
      'gridAlpha': 0,
      'tickPosition': 'start',
      'tickLength': 20
    },
    'export': {
      'enabled': true
    }
  };


  constructor() { }

  ngOnInit() {
    this.options.dataProvider = [ {
      'country': 'Bad',
      'visits': this.noteBad,
      'color': '#FF0F00'
    }, {
      'country': 'Neutral',
      'visits': this.noteNeutral,
      'color': '#ff9800'
    }, {
      'country': 'Good',
      'visits': this.noteGood,
      'color': '#4caf50'
    } ];

  }

}
