import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RoutesRecognized } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  loading = false;
  isLoggedIn = false;

  constructor(private router: Router) {
    router.events.subscribe((routerEvent: Event) => {
       this.checkRouterEvent(routerEvent);
    });
 }
 checkRouterEvent(routerEvent: Event): void {
  if (routerEvent instanceof NavigationStart) {
     this.loading = true;
  }

  if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
     this.loading = false;
  }

  if (routerEvent instanceof RoutesRecognized) {

    if (routerEvent.url === '/') {
      if (!this.isLoggedIn) {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/main']);
      }
    }
  }
}



}
