import { Component, OnInit, Inject } from '@angular/core';
import { CactusService } from '../../api/cactus.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '../../../../node_modules/@angular/material';
import { ConstantsService } from '../../utils/constants.service';

@Component({
  selector: 'app-popup-add-abuse',
  templateUrl: './popup-add-abuse.component.html',
  styleUrls: ['./popup-add-abuse.component.css']
})
export class PopupAddAbuseComponent implements OnInit {

  raisonFR = '';
  raisonEN = '';
  raisonDE = '';

  constructor(private dialogRef: MatDialogRef<PopupAddAbuseComponent>, private cactus: CactusService,
    @Inject(MAT_DIALOG_DATA) public data: any, private constants: ConstantsService) {}

  ngOnInit() {
  }

  onCloseConfirm() {
    this.cactus.pushData(this.constants.urlAddNewAbuse, {french: this.raisonFR, english: this.raisonEN, deutsch: this.raisonDE}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.onCloseConfirm();
          } else {
            console.log('error login guest admin');
          }
        });

      } else if ( res.status === 1 ) {
        this.dialogRef.close({ result: 'success', abuse: res.data, message: res.message });
      } else {
        this.dialogRef.close({result: 'error', message: res.message});
      }
    });
  }

  onCloseCancel() {
    this.raisonFR = '';
    this.raisonEN = '';
    this.raisonDE = '';
  }
}
