import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rs-pie-chart',
  templateUrl: './rs-pie-chart.component.html',
  styleUrls: ['./rs-pie-chart.component.css']
})
export class RsPieChartComponent implements OnInit {

  @Input() heightPie: string;
  @Input() notePieGood: number;
  @Input() notePieNeutral: number;
  @Input() notePieBad: number;
  @Input() notePieScore: string;

  size: any = {
    size1: 0,
    size1X: '',
    size1Y: '',
    size2: 0,
    size2X: '',
    size2Y: ''
  };
  public id = Math.floor(Math.random() * (999999 - 100000)) + 100000;
  public options = {
    'hideCredits': true,
    'type': 'pie',
    'theme': 'light',
    'dataProvider': [
      { 'sector': 'Bad', 'size': 6.6, 'color': '#F44336' },
      { 'sector': 'Good', 'size': 1.6, 'color': '#4CAF50' },
      { 'sector': 'Neutral', 'size': 23.2, 'color': '#FF9800' }],
    'valueField': 'size',
    'titleField': 'sector',
    'addClassNames': true,
    'colorField': 'color',
    'labelColorField': 'color',
    'labelsEnabled': false,
    'startDuration': 0,
    'innerRadius': '75%',
    'radius': '30%',
    'allLabels': [{
      'y': '43%',
      'align': 'center',
      'size': 25,
      'bold': true,
      'text': '5',
      'color': '#555'
    }, {
      'y': '48%',
      'x': '52%',
      'size': 20,
      'bold': true,
      'text': '/5',
      'color': '#777777'
    }]
  };

  constructor() { }

  ngOnInit() {
    if (this.heightPie == null) {
      this.heightPie = '200';
      this.size.size1 = 23;
      this.size.size2 = 10;
      this.size.size1Y = '41%';
      this.size.size2Y = '55%';
      this.size.size3Y = '47%';
    } else if (this.heightPie === '160') {
      this.heightPie = '160';
      this.size.size1 = 20;
      this.size.size2 = 7;
      this.size.size1Y = '41%';
      this.size.size2Y = '55%';
      this.size.size3Y = '47%';
    } else if (this.heightPie === '350') {
      this.size.size1 = 38;
      this.size.size2 = 15;
      this.size.size3 = 20;
      this.size.size1Y = '42%';
      this.size.size2Y = '55%';
      this.size.size3Y = '46%';
    }

    let sizeNote = 0;
    if (this.notePieBad === 0 && this.notePieGood === 0 && this.notePieNeutral === 0) {
      sizeNote = 0.1;
    }
    this.options.dataProvider = [
      { 'sector': 'Bad', 'size': this.notePieBad, 'color': '#F44336' },
      { 'sector': 'Good', 'size': this.notePieGood, 'color': '#4CAF50' },
      { 'sector': 'Neutral', 'size': this.notePieNeutral, 'color': '#FFA000' },
      { 'sector': 'None', 'size': sizeNote, 'color': '#939ca1' }
    ];
    this.options.allLabels = [{
      'y': this.size.size1Y,
      'align': 'center',
      'size': this.size.size1,
      'bold': true,
      'text': this.notePieScore,
      'color': '#555'
    }, {
      'y': this.size.size2Y,
      'align': 'center',
      'size': this.size.size2,
      'bold': true,
      'text': ' Out Of 5',
      'color': '#777777'
    }];
  }
}
