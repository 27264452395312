import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatExpansionModule,
  MatDividerModule,
  MatTooltipModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatTabsModule,
} from '@angular/material';
import { NgxMasonryModule } from 'ngx-masonry';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TimeAgoPipe } from 'time-ago-pipe';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { RouterModule } from '@angular/router';
import { routes } from './router/app.rooting';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CriteriaComponent } from './components/criteria/criteria.component';
import { CategoriesResolver } from './components/categories/categories.resolver';
import { HttpClientModule } from '@angular/common/http';
import { PopupFeedbackComponent } from './components/popup-feedback/popup-feedback.component';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from 'ngx-loading';
import * as $ from 'jquery';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CriteriaResolver } from './components/criteria/criteria.resolver';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { CategoryDetailsComponent } from './components/category-details/category-details.component';
import { CategoryDetailsResolver } from './components/category-details/category-details.resolver';
import { PopupAddCriteriasComponent } from './components/popup-add-criterias/popup-add-criterias.component';
import { PopupAddSingleCriteriaComponent } from './components/popup-add-single-criteria/popup-add-single-criteria.component';
import { PopupAddCategoryComponent } from './components/popup-add-category/popup-add-category.component';
import { ItemsListComponent } from './components/items-list/items-list.component';
import { AbusesListComponent } from './components/abuses-list/abuses-list.component';
import { PopupAddAbuseComponent } from './components/popup-add-abuse/popup-add-abuse.component';
import { AbusesResolver } from './components/abuses-list/abuses.resolver';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { UserProfileResolver } from './components/user-profile/user-profile.resolver';
import { ItemDetailsResolver } from './components/item-details/item-details.resolver';
import {AmChartsModule} from '@amcharts/amcharts3-angular';
import { RsPieChartComponent } from './components/rs-pie-chart/rs-pie-chart.component';
import { RsBarChartComponent } from './components/rs-bar-chart/rs-bar-chart.component';
import { UsersArchiveListComponent } from './components/users-archive-list/users-archive-list.component';
import { ItemsArchiveListComponent } from './components/items-archive-list/items-archive-list.component';
import {  ArchiveUserProfilComponent } from './components/archive-user-profil/archive-user-profil.component';
import { ArchiveUserProfileResolver } from './components/archive-user-profil/archive-user-profil.resolver';
import { ArchiveItemDetailsComponent } from './components/archive-item-details/archive-item-details.component';
import { AdministrateursComponent } from './components/administrateurs/administrateurs.component';
import { PopupAddAdminComponent } from './components/popup-add-admin/popup-add-admin.component';
import { PopupUpdateCategoryComponent } from './components/popup-update-category/popup-update-category.component';
import { OwnersListComponent } from './components/owners-list/owners-list.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BarChartAnimatedComponent } from './components/bar-chart-animated/bar-chart-animated.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { PopupUpdateItemComponent } from './components/popup-update-item/popup-update-item.component';
import { ListItemByCategoryComponent } from './components/list-item-by-category/list-item-by-category.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    TimeAgoPipe,
    AppComponent,
    MainNavComponent,
    DashboardComponent,
    UsersListComponent,
    LoginComponent,
    PageNotFoundComponent,
    CategoriesComponent,
    CriteriaComponent,
    PopupFeedbackComponent,
    CategoryDetailsComponent,
    PopupAddCriteriasComponent,
    PopupAddSingleCriteriaComponent,
    PopupAddCategoryComponent,
    ItemsListComponent,
    AbusesListComponent,
    PopupAddAbuseComponent,
    UserProfileComponent,
    ItemDetailsComponent,
    RsPieChartComponent,
    RsBarChartComponent,
    UsersArchiveListComponent,
    ItemsArchiveListComponent,
    ArchiveUserProfilComponent,
    ArchiveItemDetailsComponent,
    AdministrateursComponent,
    PopupAddAdminComponent,
    PopupUpdateCategoryComponent,
    OwnersListComponent,
    BarChartAnimatedComponent,
    PopupUpdateItemComponent,
    ListItemByCategoryComponent,
  ],
  imports: [
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    RouterModule.forChild(routes),
    BrowserModule,
    NgxMasonryModule,
    DragDropModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    LoadingModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatDividerModule,
    MatTooltipModule,
    ToastrModule.forRoot(),
    MatRadioModule,
    NgxChartsModule,
    AngularFontAwesomeModule,
    AmChartsModule
  ],
  entryComponents: [PopupFeedbackComponent, PopupAddCriteriasComponent, PopupAddSingleCriteriaComponent,
  PopupAddCategoryComponent, PopupAddAbuseComponent, PopupUpdateItemComponent , PopupAddAdminComponent, PopupUpdateCategoryComponent],
  providers: [CategoriesResolver, CriteriaResolver, AuthGuard, CategoryDetailsResolver, AbusesResolver,
   UserProfileResolver, ItemDetailsResolver, ArchiveUserProfileResolver],
  bootstrap: [AppComponent]
})
export class AppModule {}
