import { Component, OnInit, Inject } from '@angular/core';
import { CactusService } from '../../api/cactus.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConstantsService } from '../../utils/constants.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-popup-add-admin',
  templateUrl: './popup-add-admin.component.html',
  styleUrls: ['./popup-add-admin.component.css']
})
export class PopupAddAdminComponent implements OnInit {
  adminadded = new FormControl();
  login = '';
  email = '';
  password = '';
  constructor(private cactus: CactusService, private dialogRef: MatDialogRef<PopupAddAdminComponent>,
 private constants: ConstantsService) {

    }

  ngOnInit() {
  }
  addAdmin() {
    const criteriaList = [];
    this.cactus.pushData(this.constants.urlAddAdmin, {login: this.login, email: this.email, password: this.password}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.addAdmin();
          } else {
            console.log('error login guest admin');
          }
        });

      } else if (res.status === 1) {
        this.dialogRef.close({ result: 'success', admin: res.data, message: res.message });
      } else {
        this.dialogRef.close({result: 'error', message: res.message});
      }
    });
  }

  onCloseCancel() {
    this.login = '';
    this.email = '';
    this.password = '';
    this.adminadded.reset();
  }

}
