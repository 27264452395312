import { Component, OnInit } from '@angular/core';
import { RSResponse } from 'src/app/models/rs-response.model';
import { ActivatedRoute } from '@angular/router';
import {ModalDismissReasons, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { CactusService } from 'src/app/api/cactus.service';
import { ConstantsService } from 'src/app/utils/constants.service';
import { User } from '../../models/user.model';
import { PopupUpdateItemComponent } from '../popup-update-item/popup-update-item.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.css']
})
export class ItemDetailsComponent implements OnInit {


  old_title: any;
  old_description: any;
  flag: string;
  codeflag: any;
  countrycode: any;
  barcode: any;
  router: any;
  commentsFiltred: any;
  comments: any;
  resultpix: any;
  rsResponse?: RSResponse;
  item?: any;
  perPage = 12;

  // commentaires loader
  isLoadingComment = true;
  isCommentReached = false;
  listComment: any = [];
  pageComment = 1;
  countPageComment = 0;
  // photos loader
  isLoadingPix = true;
  isPixReached = false;
  listPix: any = [];
  pagePix = 1;
  countPagePix = 0;

  constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog, private cactus: CactusService, private urls: ConstantsService) { }

  ngOnInit() {
    this.rsResponse = this.activatedRoute.snapshot.data.value;
    this.item = this.rsResponse.data;
    this.barcode = this.item.itemDetails.barcode;
    if (this.item.itemDetails.barcode) {
    this.getCountrybybarcode(this.item.itemDetails.barcode.slice(0, 3));
  }

  }
   open(content) {
   console.log(content);
  //  this.modalStyle =  'block';
   }

  updateItem(iditem) {
            this.openDialogUpdateCategory(iditem);
    }

    openDialogUpdateCategory (iditem) {
      const DialogRef = this.dialog.open(PopupUpdateItemComponent, {
          disableClose: true,
          panelClass: 'custom-dialog',
          data: { iditem: iditem}


      });
      DialogRef.afterClosed().subscribe(result => {
        this.item.itemDetails.title = result.res.title;
        this.item.itemDetails.description = result.res.description;
      });
    }


  getCountrybybarcode(barcode) {

    const dataToSendbarcode = {
      barcode : barcode
    };
     this.cactus.pushData(this.urls.getCountryByBarcode, dataToSendbarcode).subscribe(res => {
       if (res.status === 1) {
         if (res.data !== null ) {
              this.countrycode = res.data.countryname;
              this.codeflag = res.data.countryflag;
              this.flag = 'https://www.geonames.org/flags/s/' + this.codeflag + '.png';
       }
      } else if (res.status === 401 || res.status === 403) {
         // tslint:disable-next-line:no-shadowed-variable
         this.cactus.requestSignInQuestUserAdmin().subscribe(res => {
         if (res.status === 1) {
           const tokenGuestUser = res.data.token;
           localStorage.setItem('tokenGuestUser', tokenGuestUser);
         }
       });
     }
     });
   }
  tabChanged(index) {
    if (index === 1  && this.pageComment === 1) {
      this.loadComments();
    } else if (index === 2 && this.pagePix === 1) {
      this.loadPix();
    }
  }

  loadComments() {
    this.isLoadingComment = true;
    this.cactus.pushData(this.urls.urlItemComments, {perPage: this.perPage, page: this.pageComment, itemId: this.item.itemDetails._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.loadComments();
          } else {
            console.log('error login guest admin');
          }
        });

      } else  {
        this.isLoadingComment = false;
        this.isCommentReached = false;
        this.listComment = this.listComment.concat(res.data.comments);
        this.countPageComment = res.data.pages;
        if (res.data.comments.length > 0) {
          this.pageComment++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingComment = false;
      this.isCommentReached = true;
    });
  }

  loadPix() {
    this.isLoadingPix = true;
    this.cactus.pushData(this.urls.urlItemPix, {perPage: this.perPage, page: this.pagePix, itemId: this.item.itemDetails._id}).subscribe(res => {
      if (res.status === 401 || res.status === 403) {
        this.cactus.requestSignInQuestUserAdmin()
        .subscribe(data => {
          if (data.status === 1) {
            const tokenGuestAdmin = data.data.token;
            localStorage.setItem('tokenGuestAdmin', tokenGuestAdmin);
            this.loadPix();
          } else {
            console.log('error login guest admin');
          }
        });

      } else {
        this.isLoadingPix = false;
        this.isPixReached = false;
        this.listPix = this.listPix.concat(res.data.pictures);
        this.countPagePix = res.data.pages;
        if (res.data.pictures.length > 0) {
          this.pagePix++;
        }
      }

    }, error => {
      console.log(error);
      this.isLoadingPix = false;
      this.isPixReached = true;
    });
  }

}
